import React, { useEffect } from 'react';
import * as partTypesActions from 'store/partTypes/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import { ROUTES } from 'router/routes';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import NavigationService from 'services/navigation/NavigationService';
import InputSearch from 'componentsShared/InputSearch/InputSearch';
import PartsList from 'components/Parts/PartsList/PartsList';
import { debounce } from 'helpers/functions';
import {
  EMPTY_FOUND_PARTS_CUSTOMER_ROLE_TEXT,
  EMPTY_PARTS_CUSTOMER_ROLE_TEXT,
  TIME_SEARCH_DEBOUNCE,
} from 'constants/ui';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { getQueryVariable } from 'helpers/path';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
import Loader from 'componentsShared/Loader/Loader';
import st from './PartTypesListCustomerPage.module.scss';

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

function PartTypesListCustomerPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);
  const currentPage = queryVariable.page || 0;

  const isLoadingParts =
    useSelector((state) => state?.partTypes?.partTypes?.isLoading) ?? false;
  const partList =
    useSelector((state) => state?.partTypes?.partTypes?.data) ?? {};
  const partIds =
    useSelector((state) => state?.partTypes?.partTypes.sortIds) ?? [];
  const searchValue =
    useSelector((state) => state?.partTypes?.fetchParams?.search) ?? '';
  const paginationObj =
    useSelector((state) => state?.partTypes?.fetchParams?.pagination) ?? {};
  const isLoadingSearch =
    useSelector((state) => state?.partTypes?.fetchParams?.isLoading) ?? false;
  const isScrollPart =
    useSelector((state) => state?.partTypes?.isScrollPart) ?? false;
  const scrollPartId =
    useSelector((state) => state?.partTypes?.scrollPartId) ?? '';

  const onChangeFilterSearch = (value) => {
    dispatch(partTypesActions.changeFilterSearch(value));
  };

  const loadPartTypesList = (isClearPage) => {
    dispatch(
      partTypesActions.getPartList.start({
        filter: searchValue,
        isClearPage,
        isPagination: true,
      })
    );
  };

  const handleCreatePart = () => {
    NavigationService.navigateToPath(ROUTES.createPartTypes.path);
  };

  const handleChangeSearch = (value) => {
    onChangeFilterSearch(value);
    if (value) {
      return debouncedSearch(() => {
        loadPartTypesList(true);
      });
    }
    loadPartTypesList(true);
  };

  const handleChangePage = (page) => {
    dispatch(partTypesActions.changePage(page));
    loadPartTypesList();
  };

  useEffect(() => {
    if (!+currentPage) {
      onChangeFilterSearch('');
    }
    handleChangePage(+currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isShowPart =
      isScrollPart && scrollPartId && !isLoadingParts && !isLoadingSearch;

    if (isShowPart) {
      const [partId, page] = scrollPartId.split('-');
      if (page.toString() === paginationObj.page.toString()) {
        const partCardElement = document.getElementById(`part-card-${partId}`);
        if (partCardElement) {
          partCardElement.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }

        dispatch(partTypesActions.changeIsScrollPart());
        dispatch(partTypesActions.changeScrollPartId());
      } else {
        const countPages = +Math.ceil(paginationObj.count / paginationObj.size);
        if (+page > countPages - 1) {
          dispatch(partTypesActions.changeIsScrollPart());
          dispatch(partTypesActions.changeScrollPartId());
        } else {
          handleChangePage(page);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollPart, scrollPartId, isLoadingParts, isLoadingSearch, dispatch]);

  return (
    <CenteredColumnLayout
      classNamePageContentHolder={st.layoutContentHolder}
      classNamePageWrapper={st.layoutWrapper}
      renderFooter={() => (
        <FooterPagination
          page={paginationObj.page}
          countItem={paginationObj.count}
          size={paginationObj.size}
          onChange={handleChangePage}
          className={st.footerWrapper}
          isFullLine
          isShowPlaceholder={partIds.length === 0}
          placeholder={<div className={st.placeholderFooter} />}
        />
      )}>
      <div className={st.holderHeader}>
        <HeaderContent
          title='Parts'
          isActive
          flexGrowNone
          componentActive={() => (
            <InputSearch
              className={st.holderSearch}
              placeholder='Search by number, name'
              value={searchValue}
              onChange={handleChangeSearch}
              isLoadingSearch={isLoadingSearch}
            />
          )}
          className={st.headerContainer}>
          <Button variant='primary' onClick={handleCreatePart}>
            New Part
          </Button>
        </HeaderContent>
      </div>
      <div className={st.holderPartsList}>
        <div className={st.holderPartsListContent}>
          <PartsList
            classNameList={st.partsListContainer}
            dataList={partList}
            ids={partIds}
            isCustomerRole
            isLoading={isLoadingParts}
            emptyText={EMPTY_PARTS_CUSTOMER_ROLE_TEXT}
            searchEmptyText={EMPTY_FOUND_PARTS_CUSTOMER_ROLE_TEXT}
            isSearch={!!searchValue}
            page={paginationObj.page}
          />

          <Loader
            isVisible={isLoadingParts}
            isBackgroundColorGray
            className={st.loaderWrapper}
          />
        </div>
      </div>
    </CenteredColumnLayout>
  );
}

export default PartTypesListCustomerPage;
