import React, { useState } from 'react';
import Modal from 'componentsShared/Modal/Modal';
import { func, bool, array } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as jobsActions from 'store/jobs/actions';
import JobsPartsToReject from 'components/Jobs/JobsPartsToReject/JobsPartsToReject';
import JobsReasonForRejecting from 'components/Jobs/JobsReasonForRejecting/JobsReasonForRejecting';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router-dom';
import { deleteSameValuesArray } from 'helpers/functions';
import st from './ModalJobsUnsubmiting.module.scss';

ModalJobsUnsubmiting.propTypes = {
  show: bool,
  setShow: func,
  jobIdList: array,
};

function ModalJobsUnsubmiting({
  jobIdList = [],
  show = false,
  setShow = () => {},
  isBatchJob = false,
}) {
  const location = useLocation();
  const pathUrl = location.pathname;
  const dispatch = useDispatch();
  const reasonRejectJob =
    useSelector((state) => state?.jobs?.reasonRejectJob?.data) ?? [];

  const [reason, setReason] = useState('');
  const [selectRejectJob, setSelectRejectJob] = useState([]);
  const [error, setError] = useState({
    reasonAnother: false,
    reasonRequired: false,
    rejectJob: false,
  });

  const onHideModal = () => {
    setReason('');
    setSelectRejectJob([]);
    setError({
      reasonAnother: false,
      reasonRequired: false,
      rejectJob: false,
    });
  };

  const handleRejectJob = (onCloseModal) => {
    const errorRejectJob = selectRejectJob.length === 0;
    const errorReasonRequired = !reason;

    if (errorReasonRequired || errorRejectJob) {
      setError({
        reasonAnother: '',
        reasonRequired: errorReasonRequired,
        rejectJob: errorRejectJob,
      });

      const errorIElementId = errorReasonRequired
        ? 'reasonForRejecting'
        : 'partsToReject';
      const element = document.getElementById(errorIElementId);
      element.scrollIntoView({ behavior: 'smooth' });

      return;
    }

    const isSelectAll = jobIdList.length === selectRejectJob.length;
    const currentJobIds = deleteSameValuesArray(jobIdList, selectRejectJob);

    dispatch(
      jobsActions.rejectJob.start({
        data: {
          ids: selectRejectJob,
          reason,
        },
        isSelectAll,
        currentJobIds,
        pathUrl,
        isBatchJob,
      })
    );

    onCloseModal();
    onHideModal();
  };

  const changeError = (type, value) => {
    setError((prev) => ({ ...prev, [type]: value }));
  };

  return (
    <Modal
      title='Decline Job'
      size='lg'
      show={show}
      setShow={setShow}
      onHide={onHideModal}
      heightBody='calc(100vh - 240px)'
      buttonBottomRight={({ onClose }) => (
        <div className={st.holderFooterButton}>
          <Button variant={VARIANT_OUTLINE_UI.secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => handleRejectJob(onClose)}>
            Submit Rejected
          </Button>
        </div>
      )}>
      <div className={st.bodyContent}>
        <JobsReasonForRejecting
          reason={reason}
          setReason={setReason}
          errorReasonRequired={error.reasonRequired}
          setError={changeError}
          reasonList={reasonRejectJob}
        />
        <div className={st.line} />
        <JobsPartsToReject
          jobIdList={jobIdList}
          selectRejectJob={selectRejectJob}
          setSelectRejectJob={setSelectRejectJob}
          error={error.rejectJob}
          setError={(value) => changeError('rejectJob', value)}
        />
      </div>
    </Modal>
  );
}

export default ModalJobsUnsubmiting;
