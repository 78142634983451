import React, { useEffect, useRef, useState } from 'react';
import Text from 'componentsShared/Text/Text';
import { Button, FormControl } from 'react-bootstrap';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import { QRIcon } from 'assets/icons';
import { func } from 'prop-types';
import st from './ReaderFindIdJobContent.module.scss';

ReaderFindIdJobContent.propTypes = {
  handleCheckId: func,
};

function ReaderFindIdJobContent({ handleCheckId = () => {} }) {
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  const handleChange = (e) => {
    const currentValue = e.target.value;
    setValue(currentValue);
  };

  const onCheckId = () => {
    handleCheckId(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && value) {
      onCheckId();
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className={st.container} onKeyPress={handleKeyPress}>
      <div className={st.holderIcon}>
        <QRIcon className={st.icon} />
      </div>
      <div className={st.holderContent}>
        <div className={st.holderInput}>
          <FormControl
            type='number'
            value={value}
            onChange={handleChange}
            maxLength={MAX_LENGTH_INPUT}
            ref={inputRef}
          />
        </div>

        <Button onClick={onCheckId} disabled={!value}>
          Check
        </Button>
      </div>
      <div>
        <Text variant='normal' className={st.text}>
          Number code is provided on the sticker of a part
        </Text>
      </div>
    </div>
  );
}

export default ReaderFindIdJobContent;
