import ModalJobsUnsubmiting from 'components/Modal/ModalJobsUnsubmiting/ModalJobsUnsubmiting';
import Modal from 'componentsShared/Modal/Modal';
import Text from 'componentsShared/Text/Text';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as jobsActions from 'store/jobs/actions';
import JobsOperationFooter from 'components/Jobs/JobsOperationFooter/JobsOperationFooter';
import { oneOfType, string, object, number, bool } from 'prop-types';
import JobsOperationContent from 'components/Jobs/JobsOperationContent/JobsOperationContent';
import { FILE_ACCEPT_IMAGE_AND_PDF, FILE_TYPES } from 'constants/files';
import NotificationsService from 'services/notifications/NotificationsService';
import {
  MAX_FILE_SIZE,
  NOTIFICATION_ERROR_TYPE_FILE_TITLE,
  NOTIFICATION_LARGE_SIZE_FILE_TEXT,
  NOTIFICATION_LARGE_SIZE_FILE_TITLE,
  VARIANT_UI,
} from 'constants/ui';
import st from './JobsOperation.module.scss';

JobsOperation.propTypes = {
  currentOperation: object,
  quantityOperation: oneOfType([number, string]),
  jobId: oneOfType([number, string]),
  currentOperationIndex: oneOfType([number, string]),
  isLastOperationWithNeedsQA: bool,
  isLastOperation: bool,
  isControlValues: bool,
  isFinalQA: bool,
};

function JobsOperation({
  currentOperation,
  quantityOperation,
  jobId,
  currentOperationIndex,
  isLastOperationWithNeedsQA,
  isLastOperation,
  isControlValues,
  jobIdList = [],
  isFinalQA = false,
}) {
  const dispatch = useDispatch();
  const { files: fileList, name: operationName } = currentOperation || {};

  const currentJob =
    useSelector((state) => state?.jobs?.jobs?.data?.[jobId]) ?? {};
  const jobFileList =
    useSelector((state) => state?.jobs?.files?.data?.[jobId]) ?? [];
  const bundleId = currentJob?.current_step?.bundle_id;
  const controlValuesData =
    useSelector(
      (state) => state?.controlValues?.controlValues?.data?.[bundleId]
    ) ?? [];
  const isLoadingFiles =
    useSelector((state) => state?.jobs?.files?.isLoading) ?? false;

  const [showModalUnsubmiting, setShowModalUnsubmiting] = useState(false);
  const [showModalSubmit, setShowModalSubmit] = useState(false);
  const [jobControlValues, setJobControlValues] = useState({});
  const [isShowErrorControlValues, setIsShowErrorControlValues] =
    useState(false);
  const [showModalRequireCV, setShowModalRequireCV] = useState(false);

  const showControlValues = bundleId && isControlValues;
  const isRequireFile = currentJob?.current_operation?.require_file || false;
  const jobFilePathList = [
    ...(jobFileList?.[FILE_TYPES.qaAttachment]?.map(({ path }) => path) || []),
    ...(jobFileList?.[FILE_TYPES.finalQaAttachment]?.map(({ path }) => path) ||
      []),
  ];
  const errorsControlValues = useMemo(() => {
    const currentErrors = {};

    if (
      !showControlValues ||
      !Array.isArray(controlValuesData?.headers) ||
      controlValuesData?.headers.length === 0
    ) {
      return currentErrors;
    }

    controlValuesData.headers.forEach((item) => {
      if (!jobControlValues?.[item?.value]) {
        currentErrors[item?.value] = 'Require';
      }
    });

    return currentErrors;
  }, [jobControlValues, showControlValues, controlValuesData?.headers]);

  const onCancel = () => {
    setShowModalUnsubmiting(true);
  };

  const onOpenModalSubmit = () => {
    if (showControlValues && Object.keys(errorsControlValues).length > 0) {
      setIsShowErrorControlValues(true);
      return setShowModalRequireCV(true);
    }

    setShowModalSubmit(true);
  };

  const onSubmit = () => {
    const isNeedsQA = isLastOperationWithNeedsQA && jobId;
    dispatch(
      jobsActions.submitJob.start({
        data: {
          curent_step_index: currentJob.curent_step_index,
          current_operation_index: currentJob.current_operation_index,
          values: showControlValues ? jobControlValues : null,
          ids: jobIdList,
        },
        isNeedsQA,
        isLastOperation,
      })
    );
  };

  const handleUploadFile = (file, fileName = '') => {
    dispatch(
      jobsActions.addFileInterprocessQA.start({
        idList: jobIdList,
        file,
        fileName,
      })
    );
  };

  const onRemoveFile = useCallback(
    (path) => {
      dispatch(jobsActions.removeFileInterprocessQA.start({ id: jobId, path }));
    },
    [jobId, dispatch]
  );

  const onUploadFile = (type) => (file, e) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    e.target.value = '';

    const fileTypeArray = FILE_ACCEPT_IMAGE_AND_PDF.split(',').map((item) =>
      item.trim()
    );

    if (!fileTypeArray.includes(file.type)) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_ERROR_TYPE_FILE_TITLE,
        variant: VARIANT_UI.danger,
      });

      return;
    }

    if (!file || file.size > MAX_FILE_SIZE) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_LARGE_SIZE_FILE_TEXT,
        variant: VARIANT_UI.danger,
      });
    } else {
      handleUploadFile(formData, file.name);
    }
  };

  return (
    <div className={st.container}>
      <div className={st.holderContent}>
        <div className={st.content}>
          <JobsOperationContent
            showControlValues={showControlValues}
            quantityOperation={quantityOperation}
            controlValuesData={controlValuesData}
            currentOperationIndex={currentOperationIndex}
            operationName={operationName}
            fileList={fileList}
            jobControlValues={jobControlValues}
            setJobControlValues={setJobControlValues}
            errorsControlValues={errorsControlValues}
            isShowErrorControlValues={isShowErrorControlValues}
            setIsShowErrorControlValues={setIsShowErrorControlValues}
            isRequireFile={isRequireFile}
            onUploadFile={onUploadFile}
            jobFilePathList={jobFilePathList}
            isLoadingFiles={isLoadingFiles}
            isFinalQA={isFinalQA}
            onRemoveFile={onRemoveFile}
          />
        </div>
      </div>

      <JobsOperationFooter
        onApply={onOpenModalSubmit}
        onCancel={onCancel}
        tiltleCancelBtn='Decline Job...'
        buttonsLeft={() => (
          <div>
            <Text variant='normal' className={st.footerInfoText}>
              {jobIdList.length} part{jobIdList.length === 1 ? '' : 's'} in the
              job series
            </Text>
          </div>
        )}
      />

      <ModalJobsUnsubmiting
        jobIdList={jobIdList}
        show={showModalUnsubmiting}
        setShow={setShowModalUnsubmiting}
      />

      <Modal
        title='Missing Control Values'
        show={showModalRequireCV}
        setShow={setShowModalRequireCV}
        buttonBottomRight={({ onClose }) => (
          <>
            <Button onClick={onClose}>Okay</Button>
          </>
        )}>
        <Text variant='normal'>
          You have to provide required Control Values in order to proceed
        </Text>
      </Modal>

      <Modal
        title='Submit Operation'
        show={showModalSubmit}
        setShow={setShowModalSubmit}
        titleAcceptBtn='Send Request'
        onAcceptBtn={onSubmit}>
        <Text variant='normal'>
          Are you sure you want to complete the operation?
        </Text>
      </Modal>
    </div>
  );
}

export default JobsOperation;
