import React from 'react';
import { array, func, bool, string } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import FileItem from 'componentsShared/FileItem/FileItem';
import cn from 'classnames';
import st from './FileList.module.scss';

FileList.propTypes = {
  list: array,
  handleRemoveFile: func,
  showClearIcon: bool,
  disableClearIcon: bool,
  classNameFileText: string,
  classNameHolderFileName: string,
  classNameHolderFile: string,
  classNameContainer: string,
};

function FileList({
  list = [],
  handleRemoveFile = () => {},
  showClearIcon = true,
  disableClearIcon = false,
  classNameFileText,
  classNameHolderFileName,
  classNameHolderFile,
  classNameContainer,
  isShowPreview,
  type = '',
}) {
  const renderFileItem = () => {
    return list.map((item) => {
      return (
        <FileItem
          key={uuidv4()}
          data={item}
          handleRemoveFile={handleRemoveFile}
          showClearIcon={showClearIcon}
          disableClearIcon={disableClearIcon}
          classNameFileText={classNameFileText}
          classNameHolderFileName={classNameHolderFileName}
          classNameHolderFile={classNameHolderFile}
          isShowPreview={isShowPreview}
          type={type}
        />
      );
    });
  };

  return (
    <div className={cn(st.container, classNameContainer)}>
      {list.length > 0 && renderFileItem()}
    </div>
  );
}

export default FileList;
