import React from 'react';
import { array, bool } from 'prop-types';
import { useDispatch } from 'react-redux';
import * as jobsActions from 'store/jobs/actions';
import JobsPreviewFile from 'components/Jobs/JobsPreviewFile/JobsPreviewFile';
import cn from 'classnames';
import st from './JobsOperationModalFileList.module.scss';

JobsOperationModalFileList.propTypes = {
  fileList: array,
  showControlValues: bool,
};

function JobsOperationModalFileList({
  fileList = [],
  showControlValues = false,
  onRemoveFile,
}) {
  const dispatch = useDispatch();

  const isFileList =
    fileList &&
    Array.isArray(fileList) &&
    fileList.length > 0 &&
    !showControlValues;

  const loadFile = (path) => {
    dispatch(jobsActions.getJobOperationFile.start({ path }));
  };

  const renderFileList = () => {
    return fileList.map(({ filePath, isWorkerUpload }) => {
      return (
        <JobsPreviewFile
          file={filePath}
          key={`file-item-${filePath}`}
          entityType='jobs'
          loadFile={loadFile}
          classNamePDfDocument={st.classNamePDfDocument}
          isWorkerUpload={isWorkerUpload}
          onRemoveFile={onRemoveFile}
        />
      );
    });
  };

  if (!isFileList) {
    return null;
  }

  return (
    <div
      className={cn(st.container, { [st.leftAlignment]: fileList.length > 2 })}>
      {renderFileList()}
    </div>
  );
}

export default JobsOperationModalFileList;
