import { getQueryParam } from 'helpers/path';
import { api } from './ApiService';

export const getJobList = (query) => {
  const queryParam = getQueryParam(query);
  return api.get(`/jobs${queryParam}`);
};

export const getJob = (id, query) => {
  const queryParam = getQueryParam(query);

  return api.get(`/jobs/${id}${queryParam}`);
};

export const submitJob = (data) => {
  return api.put(`/jobs/submit`, data);
};

export const takeJob = (data) => {
  return api.put(`/jobs/take`, data);
};

export const getMyJobs = (query) => {
  const queryParam = getQueryParam(query);
  return api.get(`/jobs/my${queryParam}`);
};

export const rejectJob = ({ data }) => {
  return api.put(`/jobs/decline`, data);
};

export const approveJob = ({ data }) => {
  return api.put(`/jobs/approve`, data);
};

export const cancelJob = (data) => {
  return api.put(`/jobs/cancel`, data);
};

export const moveJobToStep = ({ data }) => {
  return api.put(`/jobs/move`, data);
};

export const getJobQr = (id) => {
  return api.get(`/jobs/${id}/qr`, null, null, true);
};

export const getJobQrSeries = (data) => {
  const queryParam = getQueryParam(data);
  return api.get(`/jobs/qr_series${queryParam}`, null, null, true);
};

export const getJobSummary = (id) => {
  return api.get(`/jobs/summary/${id}`);
};

export const submitJobNCR = (data) => {
  return api.put(`/jobs/complete_ncr`, data);
};

export const getReasonRejectJob = () => {
  return api.get(`/jobs/reasons`);
};
