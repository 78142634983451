import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import { Button } from 'react-bootstrap';
import RFQList from 'components/RFQ/RFQList/RFQList';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import { useParams } from 'react-router-dom';
import * as rfqsActions from 'store/rfqs/actions';
import * as customersActions from 'store/customers/actions';
import { CUSTOMER_HAS_NO_RFQ_TEXT, NO_MATCHES_FOUND } from 'constants/ui';
import Loader from 'componentsShared/Loader/Loader';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
import st from './CustomersRFQManager.module.scss';

function CustomersRFQManager() {
  const dispatch = useDispatch();

  const { id: customerId } = useParams();

  const rfqIds = useSelector((state) => state?.rfqs?.rfqs.sortIds) ?? [];
  const rfqList = useSelector((state) => state?.rfqs?.rfqs?.data) ?? {};
  const isLoadingOrders =
    useSelector((state) => state?.rfqs?.rfqs?.isLoading) ?? false;
  const paginationObj =
    useSelector((state) => state?.rfqs?.fetchParams?.pagination) ?? {};
  const isScrollRFQ = useSelector((state) => state?.rfqs?.isScrollRFQ) ?? false;
  const scrollRFQId = useSelector((state) => state?.rfqs?.scrollRFQId) ?? '';

  const loadRFQList = (isClearPage) => {
    dispatch(
      rfqsActions.getRFQList.start({
        params: { customerId },
        showLoader: true,
        isClearPage,
        isPagination: true,
      })
    );
  };

  const handleChangePage = (page) => {
    dispatch(rfqsActions.changePage(page));
    loadRFQList();
  };

  const handleCreateRFQ = () => {
    NavigationService.navigateToPath(ROUTES.createRfq.path);
  };

  useEffect(() => {
    const isShowPart = isScrollRFQ && scrollRFQId && !isLoadingOrders;
    let timer = 0;

    if (isShowPart) {
      const [rfqId, page] = scrollRFQId.split('-');
      if (page.toString() === paginationObj.page.toString()) {
        const partCardElement = document.getElementById(`rfq-card-${rfqId}`);

        if (partCardElement) {
          timer = setTimeout(() => {
            partCardElement.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            });

            dispatch(rfqsActions.changeIsScrollRFQ());
            dispatch(rfqsActions.changeScrollRFQId());
          }, 1000);
        }
      } else {
        const countPages = +Math.ceil(paginationObj.count / paginationObj.size);
        if (+page > countPages - 1) {
          dispatch(rfqsActions.changeIsScrollRFQ());
          dispatch(rfqsActions.changeScrollRFQId());
        } else {
          handleChangePage(page);
        }
      }
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollRFQ, scrollRFQId, isLoadingOrders, dispatch, paginationObj.page]);

  useEffect(() => {
    loadRFQList();
    dispatch(customersActions.setCurrentCustomer(customerId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={st.container}>
      <HeaderContent
        isActive
        componentActive={() => {}}
        className={st.holderHeader}>
        <Button variant='primary' onClick={handleCreateRFQ}>
          New RFQ
        </Button>
      </HeaderContent>
      <div className={st.contentWrapper}>
        <RFQList
          classNameList={st.rfqList}
          dataList={rfqList}
          ids={rfqIds}
          emptyText={CUSTOMER_HAS_NO_RFQ_TEXT}
          searchEmptyText={NO_MATCHES_FOUND}
          page={paginationObj.page}
        />
        <Loader isVisible={isLoadingOrders} />
      </div>
      <FooterPagination
        page={paginationObj.page}
        countItem={paginationObj.count}
        size={paginationObj.size}
        onChange={handleChangePage}
        isShowPlaceholder={rfqIds.length === 0}
      />
    </div>
  );
}

export default CustomersRFQManager;
