/* eslint-disable react-hooks/exhaustive-deps */
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as travelersActions from 'store/travelers/actions';
import * as partTypesActions from 'store/partTypes/actions';
import * as controlValuesActions from 'store/controlValues/actions';
import { Button } from 'react-bootstrap';
import TravelerDetails from 'components/Traveler/TravelerDetails/TravelerDetails';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import Loader from 'componentsShared/Loader/Loader';
import TravelerService from 'services/traveler/TravelerService';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import Text from 'componentsShared/Text/Text';
import {
  getArrayTextStepWithoutOperations,
  getIsOperationTraveler,
  getValidTravelerForContorlValue,
  getValidValueCreateTraveler,
} from 'helpers/functions';
import { MoreVertIcon } from 'assets/icons';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import Modal from 'componentsShared/Modal/Modal';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import TravalerTemplateBreadCrumbs from 'components/TravalerTemplate/TravalerTemplateBreadCrumbs/TravalerTemplateBreadCrumbs';
import st from './TravelersDetailsPage.module.scss';

function TravelersDetailsPage() {
  const { id: travelerId } = useParams();
  const dispatch = useDispatch();
  const [showModalDiscardChanges, setShowModalDiscardChanges] = useState(false);
  const [showModalArchive, setShowModalArchive] = useState(false);
  const [showModalEmptyOperations, setShowModalEmptyOperations] =
    useState(false);
  const [activePhase, setActivePhase] = useState('');
  const [areScrollToPhase, setAreScrollToPhase] = useState('');

  const [flagTitleTooltip, setFlagTitleTooltip] = useState(false);

  const travelers =
    useSelector((state) => state?.travelers?.travelers?.data) ?? {};
  const isLoadingTravelers =
    useSelector((state) => state?.travelers?.travelers?.isLoading) ?? false;
  const isLoadingPart =
    useSelector((state) => state?.partTypes?.partTypes?.isLoading) ?? false;
  const currentTravelerValue =
    useSelector((state) => state?.travelers?.newTraveler) ?? {};
  const phaseList =
    useSelector((state) => state?.travelers?.phaseList?.data) ?? {};
  const isLoadingPhaseList =
    useSelector((state) => state?.travelers?.phaseList?.isLoading) ?? false;
  const isDirtyTraveler =
    useSelector((state) => state?.travelers?.isDirtyNewTraveler) ?? false;
  const newPhaseList =
    useSelector((state) => state?.travelers?.newPhaseList) ?? [];
  const redirectToEditTraveler =
    useSelector((state) => state?.partTypes?.redirectToEditTraveler) ?? false;

  const currentCustomerId =
    useSelector((state) => state?.customers?.currentCustomerId) ?? null;
  const currentCustomerName =
    useSelector(
      (state) => state?.customers?.customers?.data?.[currentCustomerId]?.name
    ) ?? '';

  const phaseListArray = Object.values(phaseList);

  const arrayStepWithoutOperations = useMemo(() => {
    return getArrayTextStepWithoutOperations(
      currentTravelerValue,
      (newPhaseList.length === 0 ? phaseListArray : newPhaseList) || []
    );
  }, [phaseListArray, newPhaseList, currentTravelerValue]);

  const travelerSteps =
    getValidValueCreateTraveler(currentTravelerValue)?.steps;

  const isEmptyOperations = useMemo(() => {
    const isTravelerOperations = getIsOperationTraveler(travelerSteps);
    return travelerSteps.length === 0 || !isTravelerOperations;
  }, [travelerSteps]);

  const partTypesList =
    useSelector((state) => state?.partTypes?.partTypes?.data) ?? {};

  const currentTraveler = travelers[travelerId];
  const currentPartTypesId = currentTraveler?.part_type_id ?? null;
  const currentPartTypes = partTypesList[currentPartTypesId];
  const isStatusActual = TravelerService.isStatusActual(travelerId);

  const title = useMemo(() => {
    if (!currentPartTypes?.name) {
      return `${currentCustomerName}`;
    }

    const currentRevision = currentPartTypes?.revision
      ? `-Revision-${currentPartTypes.revision}`
      : '';

    if (!currentCustomerName) {
      return `${currentPartTypes.name} ${currentPartTypes.number}${currentRevision}`;
    }

    return `${currentCustomerName}: ${currentPartTypes.name} ${currentPartTypes.number}${currentRevision}`;
  }, [currentCustomerName, currentPartTypes]);

  const phase = useMemo(() => {
    const arrayPhaseList = Object.values(phaseList);
    if (
      (!newPhaseList || newPhaseList.length === 0) &&
      arrayPhaseList.length === 0
    ) {
      return [];
    }

    if (
      (!newPhaseList || newPhaseList.length === 0) &&
      arrayPhaseList.length > 0
    ) {
      return arrayPhaseList.map((item) => ({
        ...item,
        default: true,
      }));
    }

    if (newPhaseList.length > 0) {
      return newPhaseList.map((item) => {
        const isDefaultPhase = arrayPhaseList.some(
          (currentPhase) => currentPhase.name === item.name
        );
        return {
          ...item,
          default: isDefaultPhase,
        };
      });
    }
  }, [newPhaseList, phaseList]);

  const dropdownList = isStatusActual
    ? [
        {
          content: () => (
            <Text variant='normal' className={st.textRed}>
              Archive Traveler
            </Text>
          ),
          onClick: () => setShowModalArchive(true),
        },
      ]
    : [
        {
          content: () => (
            <Text variant='normal' className={st.textRed}>
              Discard Changes
            </Text>
          ),
          onClick: () => setShowModalDiscardChanges(true),
        },
      ];

  const handleDiscardChanges = () => {
    dispatch(travelersActions.discardChangesTravelerData());
  };

  const handleArchive = () => {
    dispatch(
      travelersActions.removeTraveler.start({
        travelerId,
        callback: handleBackButton,
      })
    );
  };

  const handleUpdateTraveler = () => {
    const phaseKeyList = newPhaseList.map(({ name }) => name);
    const currentValue = getValidValueCreateTraveler(
      currentTravelerValue,
      phaseKeyList
    );
    const validValue = getValidTravelerForContorlValue(currentValue);
    const data = { ...validValue, phases: newPhaseList };

    dispatch(
      travelersActions.updateTraveler.start({
        id: travelerId,
        data,
      })
    );
    dispatch(travelersActions.changeDirtyNewTraveler());
  };

  const handleSubmitTraveler = () => {
    if (isEmptyOperations || arrayStepWithoutOperations.length > 0) {
      return setShowModalEmptyOperations(true);
    }

    const phaseKeyList = newPhaseList.map(({ name }) => name);
    const currentValue = getValidValueCreateTraveler(
      currentTravelerValue,
      phaseKeyList
    );
    const data = { ...currentValue, phases: newPhaseList };

    dispatch(
      travelersActions.submitTraveler.start({
        id: travelerId,
        data,
      })
    );
    dispatch(travelersActions.changeDirtyNewTraveler());
  };

  const handleBackButton = () => {
    if (currentPartTypesId && !redirectToEditTraveler) {
      NavigationService.replacePath(
        getRoutePath(ROUTES.partTypesDetails.path, {
          id: currentPartTypesId,
        })
      );
      return;
    }

    dispatch(partTypesActions.changeRedirectToEditTraveler(false));
    NavigationService.back();
  };

  useEffect(() => {
    dispatch(
      controlValuesActions.getControlValueList.start({ include_deleted: true })
    );
    dispatch(travelersActions.getPhaseList.start());
    dispatch(travelersActions.changeDirtyNewTraveler());
  }, [dispatch]);

  useEffect(() => {
    if (currentTraveler) {
      const partId = currentTraveler.part_type_id;
      if (partId && !currentPartTypes) {
        dispatch(partTypesActions.getPart.start(partId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travelerId, currentTraveler, currentPartTypes]);

  useEffect(() => {
    if (!currentTraveler) {
      dispatch(travelersActions.getTraveler.start(travelerId));
    } else {
      dispatch(travelersActions.updateDetailsTravelerData(currentTraveler));
      dispatch(travelersActions.updateNewPhaseList(currentTraveler.phases));
    }
  }, [dispatch, travelerId, currentTraveler]);

  return (
    <CenteredColumnLayout classNamePageContentHolder={st.pageContentHolder}>
      <HeaderContent
        flagTitleTooltip={flagTitleTooltip}
        setFlagTitleTooltip={setFlagTitleTooltip}
        triggerTitleTooltip={isStatusActual}
        backBtn={
          <ButtonBack
            isCheckModal={isDirtyTraveler}
            onClick={handleBackButton}
          />
        }
        title={title}
        isActive
        componentActive={() => (
          <div className={st.buttonGroup}>
            {!isStatusActual && (
              <>
                <Button onClick={handleSubmitTraveler}>Submit</Button>
                <Button
                  variant={VARIANT_OUTLINE_UI.secondary}
                  onClick={handleUpdateTraveler}>
                  Save Draft
                </Button>
              </>
            )}
            <DropdownButton
              list={dropdownList}
              classNameBtn={st.moreButton}
              startIcon={<MoreVertIcon />}
              btnVariant={VARIANT_OUTLINE_UI.secondary}
              arrowNone
            />
          </div>
        )}
      />
      <TravalerTemplateBreadCrumbs
        phaseList={phase}
        isEditTemplate={!isStatusActual}
        activePhase={activePhase}
        setAreScrollToPhase={setAreScrollToPhase}
        type='travelers'
      />
      {phase?.length > 0 && (
        <TravelerDetails
          setActivePhase={setActivePhase}
          areScrollToPhase={areScrollToPhase}
          setAreScrollToPhase={setAreScrollToPhase}
        />
      )}
      <Modal
        show={showModalDiscardChanges}
        setShow={setShowModalDiscardChanges}
        title='Would you like to Discard Changes?'
        onAcceptBtn={handleDiscardChanges}
        variantAcceptBtn={VARIANT_UI.danger}
        titleAcceptBtn='Discard'>
        <Text variant='normal'>Discard Changes</Text>
      </Modal>
      <Modal
        show={showModalArchive}
        setShow={setShowModalArchive}
        title='Archive Traveler'
        onAcceptBtn={handleArchive}
        variantAcceptBtn={VARIANT_UI.danger}
        titleAcceptBtn='Archive'>
        <div className={st.modalContentArchive}>
          <Text variant='normal'>Would you like to Archive Traveler</Text>
          <Text variant='normal' bold>
            {title}?
          </Text>
        </div>
      </Modal>

      <Modal
        show={showModalEmptyOperations}
        setShow={setShowModalEmptyOperations}
        title='Can not save'
        buttonBottomRight={({ onHide }) => (
          <Button variant={VARIANT_UI.primary} onClick={onHide}>
            Got It
          </Button>
        )}>
        <Text variant='normal'>
          Traveler has a step with no operations.
          <br />
          <br />
          Add operations in the Step or remove it in order to save changes.
        </Text>
      </Modal>

      <Loader
        isVisible={
          !currentTraveler ||
          !partTypesList ||
          isLoadingTravelers ||
          isLoadingPart ||
          isLoadingPhaseList
        }
        isBackgroundColorGray
      />
    </CenteredColumnLayout>
  );
}

export default TravelersDetailsPage;
