import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import Loader from 'componentsShared/Loader/Loader';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import { Button } from 'react-bootstrap';
import * as partTypesActions from 'store/partTypes/actions';
import * as customersActions from 'store/customers/actions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEqual } from 'lodash';
import PartsDetails from 'components/Parts/PartsDetails/PartsDetails';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import Text from 'componentsShared/Text/Text';
import { MoreVertIcon } from 'assets/icons';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import Modal from 'componentsShared/Modal/Modal';
import NavigationService from 'services/navigation/NavigationService';
import AuthService from 'services/auth/AuthService';
import BeforeRedirect from 'componentsShared/BeforeRedirect/BeforeRedirect';
import st from './PartTypesDetailsPage.module.scss';

const PartTypesDetailsPage = () => {
  const dispatch = useDispatch();
  const isClient = AuthService.isClient();

  const { id: partId } = useParams();

  const currentCustomerId =
    useSelector((state) => state?.customers?.currentCustomerId) ?? null;
  const currentPart =
    useSelector((state) => state?.partTypes?.partTypes?.data?.[partId]) ?? {};

  const isRemotePart = currentPart?.deleted || false;

  const customerId = currentCustomerId || currentPart.customer_id;

  const currentCustomerName =
    useSelector(
      (state) => state?.customers?.customers?.data?.[customerId]?.name
    ) ?? '';

  const isLoading =
    useSelector((state) => state?.partTypes?.partTypes?.isLoading) ?? false;

  const customerOptionList = [
    {
      name: currentCustomerName,
      value: customerId,
    },
  ];

  const travelerData = {
    travelerId: currentPart?.traveler?.id || null,
    status: currentPart?.traveler?.status || null,
  };

  const [showModalRemove, setShowModalRemove] = useState(false);

  const [initialValue, setInitialValue] = useState({
    customer: currentCustomerId || '',
    name: '',
    number: '',
    revision: '',
    revision_notes: '',
  });

  const [formValue, setFormValue] = useState({
    customer: currentCustomerId || '',
    name: '',
    number: '',
    revision: '',
    revision_notes: '',
  });

  const [formDirty, setFormDirty] = useState({
    customer: false,
    name: false,
    number: false,
    revision: false,
    revision_notes: false,
  });

  const isDirtyForm = useMemo(() => {
    return (
      Object.values(formDirty).some((item) => item) &&
      !isEqual(initialValue, formValue)
    );
  }, [formDirty, initialValue, formValue]);

  const title = useMemo(() => {
    const currentRevision = initialValue.revision
      ? `-Revision-${initialValue.revision}`
      : '';

    const customerName = isClient ? '' : `${currentCustomerName}: `;
    return `${customerName}${initialValue.name} ${initialValue.number}${currentRevision}`;
  }, [initialValue, currentCustomerName, isClient]);

  const errors = useMemo(() => {
    const currentErrors = {};

    if (!formValue.customer) {
      currentErrors.customer = 'Required';
    }
    if (!formValue.name) {
      currentErrors.name = 'Required';
    }
    if (!formValue.number) {
      currentErrors.number = 'Required';
    }
    if (!formValue.revision) {
      currentErrors.revision = 'Required';
    }

    return currentErrors;
  }, [formValue]);

  const isDisabledSave = useMemo(() => {
    return Object.keys(errors).length > 0 || !isDirtyForm || isRemotePart;
  }, [errors, isDirtyForm, isRemotePart]);

  const dropdownList = [
    {
      content: () => (
        <Text variant='normal' className={st.textRemove}>
          Remove Part
        </Text>
      ),
      onClick: () => setShowModalRemove(true),
    },
  ];

  const handleSave = () => {
    const currentValue = { ...formValue, customer_id: formValue.customer };
    dispatch(
      partTypesActions.changePart.start({ data: currentValue, id: partId })
    );
  };

  const handleRemove = () => {
    dispatch(
      partTypesActions.removePart.start({ id: partId, isPagination: true })
    );
    dispatch(partTypesActions.changeIsScrollPart(true));
    NavigationService.back();
  };

  const handleBack = () => {
    dispatch(partTypesActions.addIsNewPart());
    dispatch(partTypesActions.changeIsScrollPart(true));

    return NavigationService.back();
  };

  const handleUploadFile = (file) => {
    dispatch(partTypesActions.addFile.start({ id: partId, file }));
  };

  useEffect(() => {
    if (currentPart?.id) {
      setFormValue((prev) => ({
        ...prev,
        customer: customerId,
        name: currentPart?.name || '',
        number: currentPart?.number || '',
        revision: currentPart?.revision || '',
        revision_notes: currentPart?.revision_notes || '',
      }));
      setInitialValue((prev) => ({
        ...prev,
        customer: customerId || '',
        name: currentPart?.name || '',
        number: currentPart?.number || '',
        revision: currentPart?.revision || '',
        revision_notes: currentPart?.revision_notes || '',
      }));
    }
  }, [
    dispatch,
    currentPart.id,
    partId,
    customerId,
    currentPart.name,
    currentPart.number,
    currentPart.revision,
    currentPart.revision_notes,
  ]);

  useEffect(() => {
    dispatch(partTypesActions.getPart.start(partId));
    dispatch(partTypesActions.setCurrentPartType(partId));
    dispatch(customersActions.setCurrentCustomer(customerId));
  }, [dispatch, partId, customerId]);

  useEffect(() => {
    if (customerId && !currentCustomerName && isClient) {
      dispatch(customersActions.getCustomer.start(customerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentCustomerId, customerId]);

  return (
    <CenteredColumnLayout>
      <HeaderContent
        title={title}
        backBtn={<ButtonBack isCheckModal={isDirtyForm} onClick={handleBack} />}
        isActive
        componentActive={() => (
          <div className={st.buttonGroup}>
            <Button onClick={handleSave} disabled={isDisabledSave}>
              Save Changes
            </Button>

            <DropdownButton
              list={dropdownList}
              classNameBtn={st.moreButton}
              startIcon={<MoreVertIcon />}
              btnVariant={VARIANT_OUTLINE_UI.secondary}
              arrowNone
              disabled={isRemotePart}
            />
          </div>
        )}
      />
      <div className={st.mainContentHolder}>
        <PartsDetails
          formValue={formValue}
          setFormValue={setFormValue}
          errors={errors}
          formDirty={formDirty}
          setFormDirty={setFormDirty}
          customerOptionList={customerOptionList}
          isDisabledCustomerSelect
          isDirtyForm={isDirtyForm}
          travelerData={travelerData}
          handleUploadFile={handleUploadFile}
          isRemotePart={isRemotePart}
        />

        <Loader isVisible={isLoading} isBackgroundColorGray />
      </div>
      <Modal
        title='Remove Part'
        show={showModalRemove}
        setShow={setShowModalRemove}
        titleAcceptBtn='Remove'
        onAcceptBtn={handleRemove}
        variantAcceptBtn={VARIANT_UI.danger}>
        <div className={st.wrapperModalContent}>
          <div>
            <Text variant='normal'>Would you like to remove Part</Text>{' '}
            <Text variant='normal' bold>
              {currentCustomerName}: {initialValue.name}
            </Text>
          </div>
          <Text variant='normal' bold>
            {initialValue.number}?
          </Text>
        </div>
      </Modal>
      <BeforeRedirect when={isDirtyForm && !isLoading} />
    </CenteredColumnLayout>
  );
};

export default PartTypesDetailsPage;
