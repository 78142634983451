import { createReducer } from '@reduxjs/toolkit';
import { PAGE_SIZE } from 'constants/ui';
import { deepClone } from 'helpers/functions';
import { v4 as uuidv4 } from 'uuid';

import * as actions from './actions';

export const initialState = {
  partTypes: {
    data: {},
    sortIds: [],
    isLoading: false,
  },
  fetchParams: {
    pagination: {
      page: 0,
      size: PAGE_SIZE,
      count: 0,
    },
    search: '',
    isLoading: false,
  },
  isAddingPart: {
    partId: null,
    isNewPart: false,
    selectedNewPartId: null,
  },
  files: {
    data: {},
    dataUrl: {},
    isLoading: false,
  },
  selectedPartTypes: [],
  partTypeId: null,
  isScrollPart: false,
  scrollPartId: '',
  redirectToEditTraveler: false,
};

export const partTypesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getPart.start, (state) => ({
      ...state,
      partTypes: {
        ...state.partTypes,
        isLoading: true,
      },
    }))
    .addCase(actions.getPart.success, (state, action) => {
      const partIds = state.partTypes.sortIds;
      const currentPartids = partIds.includes(action.payload.id)
        ? partIds
        : [...partIds, action.payload.id];
      return {
        ...state,
        partTypes: {
          ...state.partTypes,
          data: { ...state.partTypes.data, ...action.payload.fetchedValue },
          sortIds: currentPartids,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getPart.failure, (state) => ({
      ...state,
      partTypes: { ...state.partTypes, isLoading: false },
    }))
    .addCase(actions.getPartList.start, (state, action) => {
      const { filter, isClearPage } = action?.payload || {};
      const currentFilter = filter || !!state.fetchParams.search;

      return {
        ...state,
        partTypes: { ...state.partTypes, isLoading: true },
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page: isClearPage ? 0 : state.fetchParams.pagination.page,
          },
          isLoading: !!currentFilter,
        },
      };
    })
    .addCase(actions.getPartList.success, (state, action) => {
      const { partListObj, partIds, page, count } = action.payload;

      return {
        ...state,
        partTypes: {
          ...state.partTypes,
          data: partListObj,
          sortIds: partIds,
          isLoading: false,
        },
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
            count,
          },
          isLoading: false,
        },
      };
    })
    .addCase(actions.getPartList.failure, (state) => ({
      ...state,
      partTypes: { ...state.partTypes, isLoading: false },
      fetchParams: {
        ...state.fetchParams,
        isLoading: false,
      },
    }))
    .addCase(actions.setCurrentPartType, (state, { payload }) => ({
      ...state,
      partTypeId: payload,
    }))
    .addCase(actions.changeFilterSearch, (state, action) => {
      const currentValue = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
          },
          search: currentValue,
        },
      };
    })
    .addCase(actions.createPart.start, (state) => ({
      ...state,
      partTypes: { ...state.partTypes, isLoading: true },
    }))
    .addCase(actions.createPart.success, (state, action) => {
      const isAddingPartId = action.payload;

      return {
        ...state,
        partTypes: { ...state.partTypes, isLoading: false },
        isAddingPart: {
          ...state.isAddingPart,
          partId: isAddingPartId,
        },
      };
    })
    .addCase(actions.createPart.failure, (state) => ({
      ...state,
      partTypes: { ...state.partTypes, isLoading: false },
    }))
    .addCase(actions.changePart.start, (state) => ({
      ...state,
      partTypes: { ...state.partTypes, isLoading: true },
    }))
    .addCase(actions.changePart.success, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        partTypes: {
          ...state.partTypes,
          data: { ...state.partTypes.data, ...data },
          isLoading: false,
        },
      };
    })
    .addCase(actions.changePart.failure, (state) => ({
      ...state,
      partTypes: { ...state.partTypes, isLoading: false },
    }))
    .addCase(actions.removePart.start, (state) => ({
      ...state,
      partTypes: { ...state.partTypes, isLoading: true },
    }))
    .addCase(actions.removePart.success, (state) => {
      return {
        ...state,
        partTypes: { ...state.partTypes, isLoading: false },
      };
    })
    .addCase(actions.removePart.failure, (state) => ({
      ...state,
      partTypes: { ...state.partTypes, isLoading: false },
    }))
    .addCase(actions.changePage, (state, action) => {
      const page = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
          },
        },
      };
    })
    .addCase(actions.clearIsAddingPart, (state) => ({
      ...state,
      isAddingPart: {
        ...state.isAddingPart,
        partId: null,
        isNewPart: false,
      },
    }))
    .addCase(actions.addIsNewPart, (state) => ({
      ...state,
      isAddingPart: {
        ...state.isAddingPart,
        isNewPart: true,
      },
    }))
    .addCase(actions.addFile.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.addFile.success, (state) => {
      return {
        ...state,
        files: { ...state.files, isLoading: false },
      };
    })
    .addCase(actions.addFile.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.getFiles.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.getFiles.success, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        files: { ...state.files, data, isLoading: false },
      };
    })
    .addCase(actions.getFiles.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.getFileListByAllType.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.getFileListByAllType.success, (state, action) => {
      const { data, dataUrl } = action.payload;
      return {
        ...state,
        files: { ...state.files, dataUrl, data, isLoading: false },
      };
    })
    .addCase(actions.getFileListByAllType.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.removeFiles.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.removeFiles.success, (state) => {
      return {
        ...state,
        files: { ...state.files, isLoading: false },
      };
    })
    .addCase(actions.removeFiles.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.addSelectedPartTypes, (state, action) => {
      const { data, isNewPart } = action.payload;

      return {
        ...state,
        selectedPartTypes: [...data],
        isAddingPart: {
          ...state.isAddingPart,
          selectedNewPartId: isNewPart || null,
        },
      };
    })
    .addCase(actions.changeQuantitySelectedPartTypes, (state, action) => {
      const { value, index } = action.payload;
      const validValue = +value > 0 ? +value : 1;
      const currentSelectPartList = deepClone(state.selectedPartTypes);
      currentSelectPartList[index].quantity = validValue;

      return {
        ...state,
        selectedPartTypes: currentSelectPartList,
      };
    })
    .addCase(actions.incrementQuantitySelectedPartTypes, (state, action) => {
      const { index } = action.payload;
      const currentSelectPartList = deepClone(state.selectedPartTypes);
      currentSelectPartList[index].quantity =
        +currentSelectPartList[index].quantity + 1;

      return {
        ...state,
        selectedPartTypes: currentSelectPartList,
      };
    })
    .addCase(actions.decrementQuantitySelectedPartTypes, (state, action) => {
      const { index } = action.payload;
      const currentSelectPartList = deepClone(state.selectedPartTypes);
      const currentQuantity = currentSelectPartList[index].quantity;
      const validQuantity = +currentQuantity === 1 ? 1 : +currentQuantity - 1;
      currentSelectPartList[index].quantity = validQuantity;

      return {
        ...state,
        selectedPartTypes: currentSelectPartList,
      };
    })
    .addCase(actions.deleteSelectedPartTypes, (state, action) => {
      const { index } = action.payload;
      const currentSelectPartList = deepClone(state.selectedPartTypes);
      currentSelectPartList.splice(index, 1);

      return {
        ...state,
        selectedPartTypes: currentSelectPartList,
      };
    })
    .addCase(actions.addInitialSelectedPartTypes, (state, action) => {
      const value = action.payload || [];

      const currentValue =
        value.length > 0
          ? value.map((item) => ({ ...item, key: uuidv4() }))
          : [];

      return {
        ...state,
        selectedPartTypes: currentValue,
      };
    })
    .addCase(actions.addQuantityForSelectedPartTypes, (state) => {
      const selectedPartTypes = deepClone(state.selectedPartTypes) || [];
      const currentSelectedPartTypes = selectedPartTypes.map((item) => ({
        ...item,
        quantity: 1,
      }));

      return {
        ...state,
        selectedPartTypes: currentSelectedPartTypes,
      };
    })
    .addCase(actions.clearSelectedNewPartId, (state) => {
      return {
        ...state,
        isAddingPart: {
          ...state.isAddingPart,
          selectedNewPartId: null,
        },
      };
    })
    .addCase(actions.getFileByType.start, (state, action) => {
      const { path } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          dataUrl: { ...state.files.dataUrl, [path]: { load: true } },
        },
      };
    })
    .addCase(actions.getFileByType.success, (state, action) => {
      const { dataUrl } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          dataUrl: { ...state.files.dataUrl, ...dataUrl },
        },
      };
    })
    .addCase(actions.getFileByType.failure, (state) => ({
      ...state,
      files: { ...state.files },
    }))
    .addCase(actions.changeIsScrollPart, (state, action) => {
      const value = action.payload || false;

      return {
        ...state,
        isScrollPart: value,
      };
    })
    .addCase(actions.changeScrollPartId, (state, action) => {
      const value = action.payload || '';

      return {
        ...state,
        scrollPartId: value,
      };
    })
    .addCase(actions.changeRedirectToEditTraveler, (state, action) => {
      const value = action.payload || false;

      return {
        ...state,
        redirectToEditTraveler: value,
      };
    });
});
