import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  object,
  number,
  func,
  oneOfType,
  bool,
  array,
  string,
} from 'prop-types';
import Tooltip from 'componentsShared/Tooltip/Tooltip';
import Text from 'componentsShared/Text/Text';
import { JOBS_STATUS } from 'constants/jobs';
import JobStatus from 'components/Jobs/JobStatus/JobStatus';
import AuthService from 'services/auth/AuthService';
import cn from 'classnames';
import * as jobsActions from 'store/jobs/actions';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import { QRIcon } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import Status from 'componentsShared/Status/Status';
import {
  ORDER_STATUS,
  READY_TO_SHIP_PHASE,
  READY_TO_SHIP_TEXT_STATUS,
} from 'constants/order';
import Highlighter from 'react-highlight-words';
import OrderItemJobCard from '../OrderItemJobCard/OrderItemJobCard';
import st from './OrderItemPartCard.module.scss';

OrderItemPartCard.propTypes = {
  position: object,
  currentJobDetails: array,
  progress: number,
  order: object,
  handleClickJobCard: oneOfType([func, bool]),
  isCustomer: bool,
  searchValue: string,
};

function OrderItemPartCard({
  position,
  currentJobDetails,
  progress,
  order,
  handleClickJobCard,
  isCustomer = false,
  findByJob = null,
  showDataOrderAndJob = '',
  searchValue = '',
}) {
  const dispatch = useDispatch();

  const isLoadingJobQR =
    useSelector((state) => state?.jobs?.jobsQr?.isLoading) ?? false;

  const isShippingRole = AuthService.isShippingRole();

  const holderTitleRef = useRef(null);
  const titleRef = useRef(null);

  const [isOpenCard, setIsOpenCard] = useState(false);
  const [showAnimationDisable, setShowAnimationDisable] = useState(false);
  const [showNotMatchinJob, setShowNotMatchinJob] = useState(false);

  const findByJobDetails = currentJobDetails.find(
    ({ job_id }) => +findByJob === job_id
  );

  const statusObject = useMemo(() => {
    const currentStatusObject = {
      done: 0,
      needsQA: 0,
      active: 0,
    };

    if (!currentJobDetails || currentJobDetails.length === 0) {
      return currentStatusObject;
    }

    const activeStatus =
      currentJobDetails.filter(({ status }) => status === JOBS_STATUS.active) ||
      [];

    const needsQAStatus =
      currentJobDetails.filter(
        ({ status }) => status === JOBS_STATUS.needsQA
      ) || [];

    const doneStatus =
      currentJobDetails.filter(({ status }) => status === JOBS_STATUS.done) ||
      [];

    currentStatusObject.active = activeStatus.length;
    currentStatusObject.needsQA = needsQAStatus.length;
    currentStatusObject.done = doneStatus.length;

    const objectKeys = Object.keys(currentStatusObject);
    const showStatus =
      objectKeys.some((key) => !!currentStatusObject[key]) && !isShippingRole;

    return {
      data: currentStatusObject,
      show: showStatus && !isCustomer,
      isAllDone: doneStatus.length === currentJobDetails.length,
    };
  }, [currentJobDetails, isCustomer, isShippingRole]);

  const isReadyToShipStatus = useMemo(() => {
    return (
      isCustomer &&
      currentJobDetails?.some?.(
        (item) => item.phase_name === READY_TO_SHIP_PHASE
      ) &&
      currentJobDetails?.every?.(
        (item) => item.phase_name === READY_TO_SHIP_PHASE || ORDER_STATUS.DONE
      )
    );
  }, [isCustomer, currentJobDetails]);

  const handleClickCard = (jobId) => {
    if (typeof handleClickJobCard === 'function') {
      return handleClickJobCard(jobId, order.id);
    }
  };

  const renderOrderItemJobCard = () => {
    return currentJobDetails.map((item) => {
      const jobId = item.job_id;
      const partCardProgress = item.progress;
      const stepName = item?.step_name || '';
      const operationName = item?.operation_name || '';
      const status = item?.status || '';
      const isNotMatchinJob = findByJobDetails && jobId !== findByJob;

      return (
        <OrderItemJobCard
          jobId={jobId}
          partCardProgress={partCardProgress}
          stepName={stepName}
          operationName={operationName}
          handleClickCard={handleClickCard}
          status={status}
          isCustomer={isCustomer}
          key={`${jobId}-${partCardProgress}`}
          isNotMatchinJob={isNotMatchinJob}
          searchValue={searchValue}
        />
      );
    });
  };

  const renderFindByJob = () => {
    const currentJob = currentJobDetails.find(
      ({ job_id }) => findByJob === job_id
    );

    if (!currentJob) {
      return;
    }

    const jobId = currentJob.job_id;
    const partCardProgress = currentJob.progress;
    const stepName = currentJob?.step_name || '';
    const operationName = currentJob?.operation_name || '';
    const status = currentJob?.status || '';
    const lengthNotMatchinJob = currentJobDetails.length - 1;

    return (
      <div>
        <OrderItemJobCard
          jobId={jobId}
          partCardProgress={partCardProgress}
          stepName={stepName}
          operationName={operationName}
          handleClickCard={handleClickCard}
          status={status}
          isCustomer={isCustomer}
          key={`${jobId}-${partCardProgress}`}
          searchValue={searchValue}
        />
        {currentJobDetails.length > 1 && (
          <div className={st.holderNotMatchinJob}>
            <Text variant='normal' className={st.notMatchinJobText}>
              {lengthNotMatchinJob} more jobs in the PO not matching the search
            </Text>
            <div
              className={st.holderNotMatchinJobShowAll}
              onClick={() => setShowNotMatchinJob(true)}>
              <Text variant='normal' className={st.notMatchinJobShowAllText}>
                View all jobs
              </Text>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderAllStatus = () => {
    const statusObjectData = statusObject.data;
    const objectKeys = Object.keys(statusObjectData);

    return objectKeys.map((key) => {
      const currentStatus = JOBS_STATUS?.[key] || '';
      return (
        <JobStatus
          status={currentStatus}
          quantity={statusObjectData[key]}
          key={`${order.id}-${currentStatus}`}
          className={st.status}
        />
      );
    });
  };

  const showQR = (e) => {
    e.stopPropagation();
    const purchaseOrderId = order.id;
    const partTypeId = position.part_type.id;

    if (purchaseOrderId && partTypeId) {
      const dataQuery = {
        partTypeId,
        purchaseOrderId,
      };
      dispatch(jobsActions.getJobSeriesQr.start(dataQuery));
    }
  };

  useEffect(() => {
    let timerAnimation = 0;
    if (showAnimationDisable) {
      timerAnimation = setTimeout(() => {
        setShowAnimationDisable(false);
      }, 1000);
    }

    return () => {
      clearTimeout(timerAnimation);
    };
  }, [showAnimationDisable]);

  useEffect(() => {
    if (findByJobDetails) {
      setIsOpenCard(true);
    }
  }, [findByJobDetails]);

  useEffect(() => {
    if (showDataOrderAndJob) {
      const [jobScroll] = showDataOrderAndJob.split('-');
      const findByJobScroll = currentJobDetails.find(
        ({ job_id }) => jobScroll.toString() === job_id.toString()
      );

      if (findByJobScroll) {
        setIsOpenCard(true);
      }
    }
  }, [showDataOrderAndJob, currentJobDetails]);

  return (
    <div key={position.id}>
      <div
        className={cn(st.orderPositionItemWrapper)}
        onClick={() => {
          if (!handleClickJobCard) {
            setShowAnimationDisable(true);
            setIsOpenCard(false);
            return;
          }

          setIsOpenCard((prev) => !prev);
        }}>
        <div
          className={cn(st.orderPositionItem, {
            [st.cursorPointer]: typeof handleClickJobCard === 'function',
            [st.closedOrderPosition]: !isOpenCard,
            [st.orderPositionItemDisabledAnimation]: showAnimationDisable,
          })}>
          <div className={st.orderItemPartInfo} ref={holderTitleRef}>
            <Tooltip
              placement='auto'
              textTooltip={`${position?.part_type?.name}`}
              isTooltip>
              <div className={st.holderPartNumberText}>
                <Text
                  className={st.orderItemPartNumberText}
                  currentRef={titleRef}>
                  <Text bold variant='normal2'>
                    <Highlighter
                      searchWords={[searchValue]}
                      autoEscape
                      textToHighlight={position?.part_type?.name}
                      className={st.highlighter}
                    />
                  </Text>{' '}
                </Text>
              </div>
            </Tooltip>

            <p className={st.orderItemPartName}>
              <Highlighter
                searchWords={[searchValue]}
                autoEscape
                textToHighlight={position?.part_type?.number}
                className={st.highlighter}
              />
              -Revision-{position?.part_type?.revision}
            </p>
          </div>
          <div
            className={cn(st.holderAdditionalInfo, {
              [st.holderAdditionalInfoCustomer]: isCustomer,
            })}>
            <div className={st.orderItemPartQuantity}>
              <Text variant='normal2' className={st.orderItemQuantity}>
                {position?.quantity} pcs
              </Text>
            </div>

            {isReadyToShipStatus && (
              <div>
                <Status
                  title={READY_TO_SHIP_TEXT_STATUS}
                  variant={VARIANT_UI.primary}
                  size='small'
                />
              </div>
            )}

            {statusObject.show && (
              <div className={st.holderAllStatus}>
                {renderAllStatus()}
                {!statusObject.isAllDone && (
                  <Button
                    className={st.buttonBottomRightQR}
                    onClick={showQR}
                    variant={VARIANT_OUTLINE_UI.primary}
                    disabled={isLoadingJobQR}
                    size='sm'>
                    <QRIcon className={st.QRIcon} />
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className={st.orderProgress} style={{ width: `${progress}%` }} />
        </div>
      </div>

      {isOpenCard && (
        <div className={st.partCardList}>
          {findByJobDetails && !showNotMatchinJob
            ? renderFindByJob()
            : renderOrderItemJobCard()}
        </div>
      )}
    </div>
  );
}

export default memo(OrderItemPartCard);
