import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import { addShowForPOList, getParsingArrayToObj } from 'helpers/functions';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import { PAGE_SIZE } from 'constants/ui';
import ApiService from 'services/api';
import AuthService from 'services/auth/AuthService';
import * as actions from './actions';

export function* getOrdersListSaga(action) {
  const {
    params,
    showLoader = true,
    isClient,
    isPagination,
    isShippingRole = false,
    status = '',
  } = action.payload || {};

  const filterSearch = yield select((state) =>
    state?.orders?.fetchParams?.search?.trim()
  ) ?? '';

  const page = yield select(
    (state) => state?.orders?.fetchParams?.pagination?.page
  ) ?? 0;

  const size = yield select(
    (state) => state?.orders?.fetchParams?.pagination?.size
  ) ?? PAGE_SIZE;

  const isFindByJob =
    !isShippingRole &&
    filterSearch &&
    filterSearch[0] === '#' &&
    filterSearch.length > 1;
  const currentFilterSearch = isFindByJob
    ? filterSearch.replace('#', '')
    : filterSearch;

  const filter = {
    ...params,
    filter: currentFilterSearch,
    page: isPagination ? page : null,
    size: isPagination ? size : null,
    find_by_job: isFindByJob,
    status: isClient && status && !filterSearch ? status : null,
  };

  let requestValue;

  const currentApi = isClient
    ? ApiService.getClientPOList
    : ApiService.getOrdersList;
  try {
    requestValue = yield call(currentApi, filter);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getOrdersList.failure());
    return;
  }
  const validData = addShowForPOList(requestValue.items);
  const ordersObj = getParsingArrayToObj(validData);
  const ordersIds = requestValue?.items?.map((item) => item.id) || [];

  yield put(
    actions.getOrdersList.success({
      fetchedValue: ordersObj,
      ids: ordersIds,
      showLoader,
      page: requestValue.page,
      count: requestValue.count,
      isFindByJob,
    })
  );
}

export function* createOrderSaga(action) {
  const isClient = AuthService.isClient();

  const params = action.payload;
  let requestValue;

  const currentApi = isClient
    ? ApiService.createClientOrder
    : ApiService.createOrder;

  try {
    requestValue = yield call(currentApi, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createOrder.failure());
    return;
  }

  NavigationService.replacePath(
    getRoutePath(ROUTES.ordersDetails.path, { id: requestValue.id })
  );

  yield put(actions.createOrder.success(requestValue.id));
}

export function* getOrderSaga(action) {
  const isClient = AuthService.isClient();

  const params = action.payload;
  let requestValue;

  const currentApi = isClient ? ApiService.getClientOrder : ApiService.getOrder;
  try {
    requestValue = yield call(currentApi, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getOrder.failure());
    return;
  }

  const data = {};
  const sortIds = [];

  if (Array.isArray(requestValue)) {
    // TODO: refactor
    requestValue.forEach((element) => {
      data[element.id] = element;
      sortIds.push(element.id);
    });
  } else {
    data[requestValue.id] = requestValue;
    sortIds.push(requestValue.id);
  }

  yield put(
    actions.getOrder.success({
      data,
      sortIds,
    })
  );
}

export function* updateOrderSaga(action) {
  const isClient = AuthService.isClient();

  const params = action.payload;
  let requestValue;

  const currentApi = isClient
    ? ApiService.updateClientOrder
    : ApiService.updateOrder;
  try {
    requestValue = yield call(currentApi, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.updateOrder.failure());
    return;
  }

  yield put(
    actions.updateOrder.success({
      data: { [requestValue.id]: requestValue },
    })
  );
}

export function* submitOrderSaga(action) {
  yield call(updateOrderSaga, action);
  const params = action.payload;

  try {
    yield call(ApiService.submitOrder, params.id, params.data);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.submitOrder.failure());
    return;
  }

  NavigationService.back();
  yield put(actions.submitOrder.success());
}

export function* submitNewOrderSaga(action) {
  const params = action.payload;
  let requestCreateValue;

  try {
    requestCreateValue = yield call(ApiService.createOrder, params);
    yield call(ApiService.submitOrder, requestCreateValue.id, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.submitNewOrder.failure());
    return;
  }

  NavigationService.back();
  yield put(actions.submitNewOrder.success(requestCreateValue.id));
}

export function* removeOrderSaga(action) {
  const isClient = AuthService.isClient();

  const params = action.payload;
  const currentApi = isClient
    ? ApiService.removeClientOrder
    : ApiService.removeOrder;

  try {
    yield call(currentApi, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removeOrder.failure());
    return;
  }

  const currentCustomerId = yield select(
    (state) => state?.customers?.currentCustomerId
  ) ?? null;

  const page = yield select(
    (state) => state?.orders?.fetchParams?.pagination?.page
  ) ?? 0;
  const size = yield select(
    (state) => state?.orders?.fetchParams?.pagination?.size
  ) ?? PAGE_SIZE;

  if (currentCustomerId && !isClient) {
    yield put(
      actions.getOrdersList.start({
        params: { customer_id: currentCustomerId, page, size },
        showLoader: true,
        isPagination: true,
      })
    );
  }

  yield put(actions.removeOrder.success());
}

export function* updateOrderScoreSaga(action) {
  const params = action.payload;
  let requestValue;

  try {
    requestValue = yield call(ApiService.updateOrderScore, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.updateOrderScore.failure());
    return;
  }

  yield put(
    actions.getOrdersList.start({
      showLoader: true,
      isPagination: true,
      params: { submitted_only: true },
    })
  );

  yield put(
    actions.updateOrder.success({
      data: { [requestValue.id]: requestValue },
    })
  );
}

export function* updateOrderEndDateSaga(action) {
  const params = action.payload;
  let requestValue;

  try {
    requestValue = yield call(ApiService.updateOrderEndDate, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.updateOrderEndDate.failure());
    return;
  }

  yield put(
    actions.getOrdersList.start({
      showLoader: true,
      isPagination: true,
      params: { submitted_only: true },
    })
  );

  yield put(
    actions.updateOrder.success({
      data: { [requestValue.id]: requestValue },
    })
  );
}

export function* cancelOrderSaga(action) {
  const { id, isProductionPage } = action.payload;
  let requestValue;

  try {
    requestValue = yield call(ApiService.cancelOrder, id);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.cancelOrder.failure());
    return;
  }

  if (isProductionPage) {
    yield put(
      actions.getOrdersList.start({
        showLoader: true,
        isPagination: true,
        params: { submitted_only: true },
      })
    );
  }

  const data = isProductionPage ? {} : { [requestValue.id]: requestValue };
  yield put(actions.cancelOrder.success(data));
}

export function* ordersSagas() {
  yield all([
    yield takeLatest(actions.getOrdersList.start, getOrdersListSaga),
    yield takeLatest(actions.createOrder.start, createOrderSaga),
    yield takeLatest(actions.getOrder.start, getOrderSaga),
    yield takeLatest(actions.updateOrder.start, updateOrderSaga),
    yield takeLatest(actions.submitOrder.start, submitOrderSaga),
    yield takeLatest(actions.submitNewOrder.start, submitNewOrderSaga),
    yield takeLatest(actions.removeOrder.start, removeOrderSaga),
    yield takeLatest(actions.updateOrderScore.start, updateOrderScoreSaga),
    yield takeLatest(actions.updateOrderEndDate.start, updateOrderEndDateSaga),
    yield takeLatest(actions.cancelOrder.start, cancelOrderSaga),
  ]);
}
