import React, { memo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import OrderItem from 'components/Order/OrderItem/OrderItem';
import { string, bool, object, array, func } from 'prop-types';
import Loader from 'componentsShared/Loader/Loader';
import PlaceholderContent from 'componentsShared/PlaceholderContent/PlaceholderContent';
import cn from 'classnames';
import st from './OrderList.module.scss';
import './OrderListStyle.module.scss';

OrderList.propTypes = {
  classNameList: string,
  dataList: object,
  ids: array,
  isCustomer: bool,
  isProductionPOCard: bool,
  handleClickJobCard: func,
  emptyText: string,
  searchEmptyText: string,
  isSearch: bool,
  searchValue: string,
};

function OrderList({
  classNameList,
  dataList = {},
  ids = [],
  isCustomer = false,
  isProductionPOCard = false,
  handleClickJobCard,
  isLoading = false,
  isBackgroundColorGrayLoader = false,
  emptyText = 'Empty',
  searchEmptyText = 'Empty',
  isSearch = false,
  findByJob = null,
  showDataOrderAndJob = '',
  page = 0,
  searchValue = '',
}) {
  const renderOrderList = () => {
    return (
      <TransitionGroup>
        {ids.map((id, index) => {
          const currentOrder = dataList[id];
          return (
            <CSSTransition
              key={id}
              timeout={{
                appear: 0,
                enter: 300,
                exit: 300,
              }}
              classNames='orderItem'>
              {/* content should be wrapped by div to hold styles that drops from CSSTransition container */}
              <div>
                <OrderItem
                  order={currentOrder}
                  isCustomer={isCustomer}
                  isProductionPOCard={isProductionPOCard}
                  handleClickJobCard={handleClickJobCard}
                  findByJob={index === 0 ? findByJob : null}
                  showDataOrderAndJob={showDataOrderAndJob}
                  page={page}
                  searchValue={searchValue}
                />
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    );
  };

  const renderEmptyContainer = () => {
    return (
      <PlaceholderContent
        emptyText={emptyText}
        searchEmptyText={searchEmptyText}
        isSearch={isSearch}
      />
    );
  };

  return (
    <div className={cn(st.container, classNameList)}>
      {ids?.length > 0 ? renderOrderList() : renderEmptyContainer()}
      <Loader
        isVisible={isLoading}
        isBackgroundColorGray={isBackgroundColorGrayLoader}
      />
    </div>
  );
}

export default memo(OrderList);
