import React, { useCallback, useEffect, useState } from 'react';
import { string, bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FilePDF from 'componentsShared/FilePDF/FilePDF';
import ModalJobsOperationPreviewFile from 'components/Modal/ModalJobsOperationPreviewFile/ModalJobsOperationPreviewFile';
import Text from 'componentsShared/Text/Text';
import { getFileName, getIconFile, shortenedFileName } from 'helpers/file';
import Skeleton from 'componentsShared/Skeleton/Skeleton';
import Loader from 'componentsShared/Loader/Loader';
import * as jobsActions from 'store/jobs/actions';
import {
  ArrowBarUpIcon,
  DownloadCloudIcon,
  EyeIcon,
  TrashIcon,
} from 'assets/icons';
import cn from 'classnames';
import { TYPES_DOCS_FILE_ARRAY, TYPES_XLS_FILE_ARRAY } from 'constants/files';
import DocsImage from 'assets/images/docx.png';
import ExcelImage from 'assets/images/excel.png';
import * as filesActions from 'store/files/actions';
import st from './JobsPreviewFile.module.scss';

JobsPreviewFile.propTypes = {
  file: string,
  classNameHolderFile: string,
  classNameHolderImg: string,
  entityType: string,
  showImg: bool,
};

function JobsPreviewFile({
  file,
  name,
  showImg = true,
  classNameHolderFile,
  classNameHolderImg,
  classNameContainer,
  classNameHolderFileName,
  classNameImg,
  entityType = 'jobs',
  loadFile = () => {},
  heightPDF = 150,
  classNamePDfDocument,
  isWorkerUpload = false,
  onRemoveFile,
}) {
  const dispatch = useDispatch();

  const currentFileValue =
    useSelector((state) => state?.[entityType]?.files?.dataUrl?.[file]) ?? {};
  const loadFileName =
    useSelector((state) => state?.[entityType]?.files?.loadFileName) ?? '';

  const [showModal, setShowModal] = useState(false);

  const isTypePDF = currentFileValue?.type?.includes('pdf') || false;
  const isTypeDOC = TYPES_DOCS_FILE_ARRAY.some((item) =>
    currentFileValue?.type?.includes(item)
  );
  const isTypeXLS = TYPES_XLS_FILE_ARRAY.some((item) =>
    currentFileValue?.type?.includes(item)
  );

  const validFileName = shortenedFileName(getFileName(file));

  const handleRemoveFile = useCallback(
    (e) => {
      e.stopPropagation();
      onRemoveFile?.(file);
    },
    [onRemoveFile, file]
  );

  const handleClickFile = () => {
    if (isTypeDOC || isTypeXLS) {
      dispatch(
        filesActions.getFile.start({
          path: file,
          download: true,
          fileName: validFileName,
        })
      );

      return;
    }

    setShowModal(true);
  };

  const renderUrlFile = () => {
    if (isTypeDOC || isTypeXLS) {
      const fileImg = isTypeDOC ? DocsImage : ExcelImage;

      return (
        <img
          src={fileImg}
          className={cn(st.operationFile)}
          alt='Job Operation Img'
        />
      );
    }

    if (isTypePDF) {
      return (
        <FilePDF
          currentUrl={currentFileValue?.url}
          pageNumber={1}
          widthPDF={null}
          heightPDF={heightPDF}
          isLoadSkeleton
          classNameSkeleton={st.classNameSkeleton}
          classNamePDfDocument={classNamePDfDocument}
        />
      );
    }

    return (
      <img
        src={currentFileValue?.url}
        className={cn(st.operationImg, classNameImg)}
        alt='Job Operation Img'
      />
    );
  };

  useEffect(() => {
    if (!currentFileValue?.load) {
      loadFile(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, file]);

  useEffect(() => {
    let timer = 0;
    if (loadFileName) {
      timer = setTimeout(() => {
        dispatch(jobsActions.clearLoadFileName());
      }, 2500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [loadFileName, dispatch]);

  if (loadFileName && file.includes(loadFileName)) {
    return (
      <div className={cn(st.container, classNameContainer)}>
        <div className={cn(st.holderImg, classNameHolderImg)}>
          <Loader isVisible className={st.loader} text='Uploading...' />
        </div>
        <div className={cn(classNameHolderFileName)}>
          <Text variant='normal2' bold className={st.filePreviewName}>
            {validFileName}
          </Text>
        </div>
      </div>
    );
  }

  if (!currentFileValue?.url) {
    return (
      <div className={cn(st.container, classNameContainer)}>
        <div className={cn(st.holderImg, classNameHolderImg)}>
          <Skeleton isVisible classNameSkeleton={st.classNameSkeleton} />
        </div>
        <div className={cn(classNameHolderFileName)}>
          <Text variant='normal2' bold className={st.filePreviewName}>
            {validFileName}
          </Text>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={cn(st.container, classNameContainer)}>
        <div
          className={cn(st.holderImg, classNameHolderImg, {
            [st.holderImgWorkerUpload]: isWorkerUpload,
            [st.holderFileDocOrXls]: isTypeDOC || isTypeXLS,
          })}
          onClick={handleClickFile}>
          <div
            className={cn(st.holderFile, classNameHolderFile, {
              [st.holderFilePDF]: isTypePDF,
            })}>
            {showImg ? (
              renderUrlFile()
            ) : (
              <div className={st.holderImgName}>
                <div className={st.holderFile}>
                  {getIconFile(file, st.iconFile)}
                </div>
                <Text variant='normal' className={st.fileName}>
                  {name}
                </Text>
              </div>
            )}
          </div>
          {isWorkerUpload && (
            <>
              <div className={st.borderWorkerUploadFile} />
              <ArrowBarUpIcon className={st.iconWorkerUploadFile} />
            </>
          )}
          <div className={st.hoverHintWrapper}>
            <div className={st.hoverHintHolder}>
              {isTypeDOC || isTypeXLS ? (
                <DownloadCloudIcon className={st.hoverHintIcon} />
              ) : (
                <EyeIcon className={st.hoverHintIcon} />
              )}
            </div>
          </div>
          {onRemoveFile && (
            <div className={st.holderTrashIcon} onClick={handleRemoveFile}>
              <TrashIcon className={st.trashIcon} />
            </div>
          )}
        </div>
        <div className={cn(classNameHolderFileName)}>
          <Text variant='normal2' bold className={st.filePreviewName}>
            {validFileName}
          </Text>
        </div>
      </div>
      <ModalJobsOperationPreviewFile
        showModal={showModal}
        setShowModal={setShowModal}
        file={file}
        entityType={entityType}
      />
    </>
  );
}

export default JobsPreviewFile;
