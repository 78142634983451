import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import { Button } from 'react-bootstrap';
import {
  CUSTOMER_HAS_NO_PO_TEXT,
  NO_MATCHES_FOUND,
  TIME_SEARCH_DEBOUNCE,
  VARIANT_OUTLINE_UI,
  VARIANT_UI,
} from 'constants/ui';
import * as ordersActions from 'store/orders/actions';
import * as customersActions from 'store/customers/actions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import OrderList from 'components/Order/OrderList/OrderList';
import InputSearch from 'componentsShared/InputSearch/InputSearch';
import { debounce } from 'helpers/functions';
import { getRoutePath } from 'helpers/path';
import AuthService from 'services/auth/AuthService';
import Loader from 'componentsShared/Loader/Loader';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
import { ORDER_STATUS } from 'constants/order';
import st from './CustomersPOManagerShipping.module.scss';

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

function CustomersPOManagerShipping() {
  const { id: customerId } = useParams();

  const dispatch = useDispatch();

  const isLoadingOrders =
    useSelector((state) => state?.orders?.orders?.isLoading) ?? false;
  const orderList = useSelector((state) => state?.orders?.orders?.data) ?? {};
  const orderIds = useSelector((state) => state?.orders?.orders.sortIds) ?? [];
  const searchValue =
    useSelector((state) => state?.orders?.fetchParams?.search) ?? '';
  const isLoadingSearch =
    useSelector((state) => state?.orders?.fetchParams?.isLoading) ?? false;
  const paginationObj =
    useSelector((state) => state?.orders?.fetchParams?.pagination) ?? {};
  const isFindByJob =
    useSelector((state) => state?.orders?.fetchParams?.isFindByJob) ?? false;
  const showDataOrderAndJob =
    useSelector((state) => state?.orders?.dataOrderAndJob) ?? '';
  const isScrollJob =
    useSelector((state) => state?.orders?.isScrollJob) ?? false;

  const isShippingRole = AuthService.isShippingRole();

  const [isScrollJobState, setIsScrollJobState] = useState(false);

  const findByJob =
    isFindByJob && !isShippingRole ? +searchValue.replace('#', '') : null;

  const handleCreateOrder = () => {
    NavigationService.navigateToPath(ROUTES.createOrder.path);
  };

  const loadPOList = (isClearPage) => {
    dispatch(
      ordersActions.getOrdersList.start({
        params: { customer_id: customerId, filter: searchValue },
        showLoader: true,
        isClearPage,
        isPagination: true,
        isShippingRole,
      })
    );
  };

  const onChangeFilterSearch = (value) => {
    dispatch(ordersActions.changeFilterSearch(value));
  };

  const handleChangeSearch = (value) => {
    onChangeFilterSearch(value);
    if (value) {
      return debouncedSearch(() => {
        loadPOList(true);
      });
    }
    loadPOList(true);
  };

  const handleChangePage = (page) => {
    dispatch(ordersActions.changePage(page));
    loadPOList();
  };

  const handleClickJobCard = (jobId, orderId) => {
    dispatch(
      ordersActions.changeDataOrderAndJob(
        `${jobId}-${orderId}-${paginationObj.page}`
      )
    );

    NavigationService.navigateToPath(
      getRoutePath(ROUTES.productionDetailsManager.path, {
        id: jobId,
      })
    );
  };

  useEffect(() => {
    loadPOList();
    dispatch(customersActions.setCurrentCustomer(customerId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerId]);

  useEffect(() => {
    if (customerId) {
      dispatch(customersActions.getCustomer.start(customerId));
    }
  }, [dispatch, customerId]);

  useEffect(() => {
    if (!isScrollJob) {
      onChangeFilterSearch('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const isShowJob =
      showDataOrderAndJob &&
      isScrollJob &&
      orderList &&
      !isLoadingOrders &&
      typeof paginationObj.page === 'number';

    if (isShowJob) {
      const [jobId, order, page] = showDataOrderAndJob.split('-');

      if (page.toString() === paginationObj.page.toString()) {
        setTimeout(() => {
          const currentId =
            jobId === ORDER_STATUS.DRAFT
              ? `order-card-${order}`
              : `job-card-${jobId}`;

          const element = document.getElementById(currentId);

          if (element) {
            element.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            });
          }
        }, 1000);
      } else {
        const countPages = +Math.ceil(paginationObj.count / paginationObj.size);
        if (+page > countPages - 1) {
          dispatch(ordersActions.changeIsScrollJob());
        } else {
          handleChangePage(page);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showDataOrderAndJob,
    isScrollJob,
    orderList,
    isLoadingOrders,
    paginationObj.page,
  ]);

  useEffect(() => {
    if (isScrollJob) {
      setIsScrollJobState(true);
      dispatch(ordersActions.changeIsScrollJob());
    }
  }, [isScrollJob, dispatch]);

  return (
    <div className={st.container}>
      <HeaderContent
        isActive
        componentActive={() => (
          <InputSearch
            value={searchValue}
            onChange={handleChangeSearch}
            className={st.holderSearch}
            placeholder='Search by PO#, Part'
            isLoadingSearch={isLoadingSearch}
          />
        )}
        className={st.holderHeader}>
        <div className={st.containerActionsHeader}>
          <Button variant={VARIANT_UI.primary} onClick={handleCreateOrder}>
            New PO
          </Button>

          <div className={st.dividingLine} />

          <Button
            variant={VARIANT_OUTLINE_UI.secondary}
            onClick={() => dispatch(ordersActions.orderExpandAll())}>
            Expand All
          </Button>
          <Button
            variant={VARIANT_OUTLINE_UI.secondary}
            onClick={() => dispatch(ordersActions.orderCollapseAll())}>
            Collapse All
          </Button>
        </div>
      </HeaderContent>
      <div className={st.contentWrapper}>
        <OrderList
          classNameList={st.orderList}
          dataList={orderList}
          ids={orderIds}
          isLoading={isLoadingOrders}
          emptyText={CUSTOMER_HAS_NO_PO_TEXT}
          searchEmptyText={NO_MATCHES_FOUND}
          isSearch={!!searchValue}
          handleClickJobCard={isShippingRole ? null : handleClickJobCard}
          findByJob={findByJob}
          showDataOrderAndJob={isScrollJobState ? showDataOrderAndJob : ''}
          page={paginationObj.page}
        />
        <Loader isVisible={isLoadingOrders} />
      </div>
      <FooterPagination
        page={paginationObj.page}
        countItem={paginationObj.count}
        size={paginationObj.size}
        onChange={handleChangePage}
        isShowPlaceholder={orderIds.length === 0}
      />
    </div>
  );
}

export default CustomersPOManagerShipping;
