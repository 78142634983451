import React, { useEffect } from 'react';
import * as partTypesActions from 'store/partTypes/actions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { ROUTES } from 'router/routes';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import Loader from 'componentsShared/Loader/Loader';
import NavigationService from 'services/navigation/NavigationService';
import InputSearch from 'componentsShared/InputSearch/InputSearch';
import PartsList from 'components/Parts/PartsList/PartsList';
import { debounce } from 'helpers/functions';
import {
  CUSTOMER_HAS_NO_PARTS_TEXT,
  NO_MATCHES_FOUND,
  TIME_SEARCH_DEBOUNCE,
} from 'constants/ui';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
import st from './CustomersPartManagerShipping.module.scss';

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

function CustomersPartManagerShipping() {
  const { id: customerId } = useParams();
  const dispatch = useDispatch();

  const isLoadingParts =
    useSelector((state) => state?.partTypes?.partTypes?.isLoading) ?? false;
  const partList =
    useSelector((state) => state?.partTypes?.partTypes?.data) ?? {};
  const partIds =
    useSelector((state) => state?.partTypes?.partTypes.sortIds) ?? [];
  const searchValue =
    useSelector((state) => state?.partTypes?.fetchParams?.search) ?? '';
  const paginationObj =
    useSelector((state) => state?.partTypes?.fetchParams?.pagination) ?? {};
  const isLoadingSearch =
    useSelector((state) => state?.partTypes?.fetchParams?.isLoading) ?? false;
  const isScrollPart =
    useSelector((state) => state?.partTypes?.isScrollPart) ?? false;
  const scrollPartId =
    useSelector((state) => state?.partTypes?.scrollPartId) ?? '';

  const handleCreatePart = () => {
    NavigationService.navigateToPath(ROUTES.createPartTypes.path);
  };

  const onChangeFilterSearch = (value) => {
    dispatch(partTypesActions.changeFilterSearch(value));
  };

  const loadPartTypesList = (isClearPage) => {
    dispatch(
      partTypesActions.getPartList.start({
        isClearPage,
        customer_id: customerId,
        filter: searchValue,
        isPagination: true,
      })
    );
  };

  const handleChangeSearch = (value) => {
    onChangeFilterSearch(value);
    if (value) {
      return debouncedSearch(() => {
        loadPartTypesList(true);
      });
    }
    loadPartTypesList(true);
  };

  const handleChangePage = (page) => {
    dispatch(partTypesActions.changePage(page));
    loadPartTypesList();
  };

  useEffect(() => {
    loadPartTypesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const isShowPart =
      isScrollPart && scrollPartId && !isLoadingParts && !isLoadingSearch;
    let timer = 0;

    if (isShowPart) {
      const [partId, page] = scrollPartId.split('-');

      if (page.toString() === paginationObj.page.toString()) {
        const partCardElement = document.getElementById(`part-card-${partId}`);

        if (partCardElement) {
          timer = setTimeout(() => {
            partCardElement.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            });

            dispatch(partTypesActions.changeIsScrollPart());
            dispatch(partTypesActions.changeScrollPartId());
          }, 1000);
        }
      } else {
        const countPages = +Math.ceil(paginationObj.count / paginationObj.size);
        if (+page > countPages - 1) {
          dispatch(partTypesActions.changeIsScrollPart());
          dispatch(partTypesActions.changeScrollPartId());
        } else {
          handleChangePage(page);
        }
      }
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isScrollPart,
    scrollPartId,
    isLoadingParts,
    isLoadingSearch,
    dispatch,
    paginationObj.page,
  ]);

  return (
    <div className={st.container}>
      <HeaderContent
        isActive
        componentActive={() => (
          <InputSearch
            value={searchValue}
            onChange={handleChangeSearch}
            className={st.holderSearch}
            placeholder='Search by number, name'
            isLoadingSearch={isLoadingSearch}
          />
        )}
        className={st.holderHeader}>
        <Button variant='primary' onClick={handleCreatePart}>
          New Part
        </Button>
      </HeaderContent>
      <div className={st.contentWrapper}>
        <PartsList
          classNameList={st.partList}
          dataList={partList}
          ids={partIds}
          isLoading={isLoadingParts}
          emptyText={CUSTOMER_HAS_NO_PARTS_TEXT}
          searchEmptyText={NO_MATCHES_FOUND}
          isSearch={!!searchValue}
          page={paginationObj.page}
        />
        <Loader isVisible={isLoadingParts} />
      </div>
      <FooterPagination
        page={paginationObj.page}
        countItem={paginationObj.count}
        size={paginationObj.size}
        onChange={handleChangePage}
        isShowPlaceholder={partIds.length === 0}
      />
    </div>
  );
}

export default CustomersPartManagerShipping;
