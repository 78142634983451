import React, { memo } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { getQueryParam, getQueryVariable } from 'helpers/path';
import { v4 as uuidv4 } from 'uuid';
import NavigationService from 'services/navigation/NavigationService';
import { ORDER_STATUS } from 'constants/order';
import st from './OrderListCustomerWrapper.module.scss';
import OrderList from '../OrderList/OrderList';
import OrderListCustomer from '../OrderListCustomer/OrderListCustomer';

function OrderListCustomerWrapper({
  classNameList,
  dataList = {},
  ids = [],
  isCustomer = false,
  isProductionPOCard = false,
  handleClickJobCard,
  isLoading = false,
  isBackgroundColorGrayLoader = false,
  emptyText = 'Empty',
  searchEmptyText = 'Empty',
  isSearch = false,
  findByJob = null,
  showDataOrderAndJob = '',
  page = 0,
  footer,
  loadPOList,
  type,
  setType,
  isSearchClient,
}) {
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);

  if (isSearchClient) {
    return (
      <OrderList
        classNameList={classNameList}
        dataList={dataList}
        ids={ids}
        isCustomer={isCustomer}
        isProductionPOCard={isProductionPOCard}
        handleClickJobCard={handleClickJobCard}
        isLoading={isLoading}
        isBackgroundColorGrayLoader={isBackgroundColorGrayLoader}
        emptyText={emptyText}
        searchEmptyText={searchEmptyText}
        isSearch={isSearch}
        findByJob={findByJob}
        showDataOrderAndJob={showDataOrderAndJob}
        page={page}
      />
    );
  }

  const createTab = (title, status) => ({
    title,
    key: status,
    component: (
      <OrderListCustomer
        dataList={dataList}
        ids={ids}
        isCustomer={isCustomer}
        isProductionPOCard={isProductionPOCard}
        handleClickJobCard={handleClickJobCard}
        isLoading={isLoading}
        isBackgroundColorGrayLoader={isBackgroundColorGrayLoader}
        emptyText={emptyText}
        searchEmptyText={searchEmptyText}
        isSearch={isSearch}
        findByJob={findByJob}
        showDataOrderAndJob={showDataOrderAndJob}
        page={page}
        footer={footer}
      />
    ),
  });

  const tabList = [
    createTab('Draft', ORDER_STATUS.DRAFT),
    createTab('Active', ORDER_STATUS.ACTIVE),
    createTab('Done', ORDER_STATUS.DONE),
    createTab('Canceled', ORDER_STATUS.CANCELED),
  ];

  const handleChangeTab = (item) => {
    setType(item);
    const queryParams = {
      ...queryVariable,
      page: 0,
      type: item,
    };

    loadPOList(true, item);
    const queryParamsString = getQueryParam(queryParams);
    const path = `${location.pathname}${queryParamsString}`;
    NavigationService.replacePath(path);
  };

  const onSelectTab = (item) => {
    handleChangeTab(item);
  };

  const renderTabList = () => {
    return tabList.map(({ title, component, key }) => {
      return (
        <Tab eventKey={key} title={title} key={key}>
          {component}
        </Tab>
      );
    });
  };

  return (
    <div className={st.tabsWrapper}>
      <Tabs
        activeKey={type}
        defaultActiveKey={tabList[0]?.key}
        id={uuidv4()}
        onSelect={onSelectTab}>
        {renderTabList()}
      </Tabs>
    </div>
  );
}

export default memo(OrderListCustomerWrapper);
