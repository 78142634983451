import React, { useEffect } from 'react';
import * as rfqsActions from 'store/rfqs/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import { ROUTES } from 'router/routes';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import NavigationService from 'services/navigation/NavigationService';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { getQueryVariable } from 'helpers/path';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
import Loader from 'componentsShared/Loader/Loader';
import {
  EMPTY_FOUND_RFQ_CUSTOMER_ROLE_TEXT,
  EMPTY_RFQ_CUSTOMER_ROLE_TEXT,
} from 'constants/ui';
import RFQList from 'components/RFQ/RFQList/RFQList';
import st from './RFQListCustomerPage.module.scss';

function RFQListCustomerPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);
  const currentPage = queryVariable.page || 0;

  const isLoading =
    useSelector((state) => state?.rfqs?.rfqs?.isLoading) ?? false;
  const rfqList = useSelector((state) => state?.rfqs?.rfqs?.data) ?? {};
  const rfqIds = useSelector((state) => state?.rfqs?.rfqs.sortIds) ?? [];
  const searchValue =
    useSelector((state) => state?.rfqs?.fetchParams?.search) ?? '';
  const paginationObj =
    useSelector((state) => state?.rfqs?.fetchParams?.pagination) ?? {};
  const isScrollRFQ = useSelector((state) => state?.rfqs?.isScrollRFQ) ?? false;
  const scrollRFQId = useSelector((state) => state?.rfqs?.scrollRFQId) ?? '';

  const onChangeFilterSearch = (value) => {
    dispatch(rfqsActions.changeFilterSearch(value));
  };

  const loadRFQList = (isClearPage) => {
    dispatch(
      rfqsActions.getRFQList.start({
        filter: searchValue,
        isClearPage,
        isPagination: true,
        showLoader: true,
      })
    );
  };

  const handleCreateRFQ = () => {
    NavigationService.navigateToPath(ROUTES.createRfq.path);
  };

  const handleChangePage = (page) => {
    dispatch(rfqsActions.changePage(page));
    loadRFQList();
  };

  useEffect(() => {
    if (!+currentPage) {
      onChangeFilterSearch('');
    }
    handleChangePage(+currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isShowPart = isScrollRFQ && scrollRFQId && !isLoading;

    if (isShowPart) {
      const [rfqId, page] = scrollRFQId.split('-');
      if (page.toString() === paginationObj.page.toString()) {
        const partCardElement = document.getElementById(`rfq-card-${rfqId}`);

        if (partCardElement) {
          setTimeout(() => {
            partCardElement.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            });

            dispatch(rfqsActions.changeIsScrollRFQ());
            dispatch(rfqsActions.changeScrollRFQId());
          }, 1000);
        }
      } else {
        const countPages = +Math.ceil(paginationObj.count / paginationObj.size);
        if (+page > countPages - 1) {
          dispatch(rfqsActions.changeIsScrollRFQ());
          dispatch(rfqsActions.changeScrollRFQId());
        } else {
          handleChangePage(page);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollRFQ, scrollRFQId, isLoading, dispatch, paginationObj.page]);

  return (
    <CenteredColumnLayout
      classNamePageContentHolder={st.layoutContentHolder}
      classNamePageWrapper={st.layoutWrapper}
      renderFooter={() => (
        <FooterPagination
          page={paginationObj.page}
          countItem={paginationObj.count}
          size={paginationObj.size}
          onChange={handleChangePage}
          className={st.footerWrapper}
          isFullLine
          isShowPlaceholder={rfqIds.length === 0}
          placeholder={<div className={st.placeholderFooter} />}
        />
      )}>
      <div className={st.holderHeader}>
        <HeaderContent
          title='RFQs'
          isActive
          componentActive={() => {}}
          flexGrowNone
          className={st.headerContainer}>
          <Button variant='primary' onClick={handleCreateRFQ}>
            New RFQ
          </Button>
        </HeaderContent>
      </div>
      <div className={st.holderRFQsList}>
        <div className={st.holderRFQsListContent}>
          <RFQList
            classNameList={st.RFQsListContainer}
            dataList={rfqList}
            ids={rfqIds}
            emptyText={EMPTY_RFQ_CUSTOMER_ROLE_TEXT}
            searchEmptyText={EMPTY_FOUND_RFQ_CUSTOMER_ROLE_TEXT}
            isSearch={!!searchValue}
            page={paginationObj.page}
          />

          <Loader
            isVisible={isLoading}
            isBackgroundColorGray
            className={st.loaderWrapper}
          />
        </div>
      </div>
    </CenteredColumnLayout>
  );
}

export default RFQListCustomerPage;
