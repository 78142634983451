import React, { useEffect, useRef } from 'react';
import RFQConstructor from 'components/RFQ/RFQConstructor/RFQConstructor';
import Text from 'componentsShared/Text/Text';
import * as rfqsActions from 'store/rfqs/actions';
import * as partTypesActions from 'store/partTypes/actions';
import { FormControl, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { func, string, object, bool } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { deepClone } from 'helpers/functions';
import {
  MAX_FILE_SIZE,
  MAX_LENGTH_INPUT,
  NOTIFICATION_ERROR_TYPE_FILE_TITLE,
  NOTIFICATION_LARGE_SIZE_FILE_TEXT,
  NOTIFICATION_LARGE_SIZE_FILE_TITLE,
  VARIANT_OUTLINE_UI,
  VARIANT_UI,
} from 'constants/ui';
import PartsCardConstructor from 'components/Parts/PartsCardConstructor/PartsCardConstructor';
import autosize from 'autosize';
import { useParams } from 'react-router-dom';
import RFQService from 'services/rfq/RFQService';
import RequiredLabel from 'componentsShared/RequiredLabel/RequiredLabel';
import cn from 'classnames';
import { FILE_ACCEPT_IMAGE_AND_PDF, FILE_TYPES } from 'constants/files';
import FileList from 'componentsShared/FileList/FileList';
import AttachFilesButton from 'components/AttachFilesButton/AttachFilesButton';
import RFQCommentsWrapper from 'components/RFQ/RFQCommentsWrapper/RFQCommentsWrapper';
import NotificationsService from 'services/notifications/NotificationsService';
import st from './RFQDetails.module.scss';

RFQDetails.propTypes = {
  emptyText: string,
  rfqInfo: object,
  setRFQInfo: func,
  isNewRFQ: bool,
  currentRFQ: object,
};

function RFQDetails({
  emptyText = 'Nothing has been selected yet',
  rfqInfo = {},
  setRFQInfo = () => {},
  isNewRFQ = false,
  currentRFQ = {},
}) {
  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const textareaRef = useRef(null);
  const descriptionId = uuidv4();
  const { id: rfqId } = useParams();
  const isActiveStatus = RFQService.isStatusActive(rfqId);

  const partTypeList =
    useSelector((state) => state?.partTypes?.partTypes?.data) ?? {};
  const currentDataFiles =
    useSelector((state) => state?.rfqs?.files?.data?.[rfqId]) ?? {};
  const isLoadingFile =
    useSelector((state) => state?.rfqs?.files?.isLoading) ?? false;
  const selectedPartTypesList =
    useSelector((state) => state?.partTypes?.selectedPartTypes) ?? [];
  const selectedNewPartId =
    useSelector((state) => state?.partTypes?.isAddingPart?.selectedNewPartId) ??
    null;

  const handleChange = (e) => {
    const value = e.target.value || '';
    setRFQInfo((prev) => ({ ...prev, description: value }));
  };

  const addRFQPositions = (value) => {
    const partTypeId = value.part_type_id;

    const currentSelectedPartTypesList = deepClone(selectedPartTypesList);
    let result = [];
    const indexSelectPart = currentSelectedPartTypesList.findIndex(
      (item) => item.id === partTypeId
    );

    if (indexSelectPart !== -1) {
      result = [...currentSelectedPartTypesList];
    } else {
      const currentData = {
        ...partTypeList[value.part_type_id],
        quantity: value.quantity,
        key: uuidv4(),
      };
      result = [...selectedPartTypesList, currentData];
    }

    dispatch(
      partTypesActions.addSelectedPartTypes({
        data: result,
        isNewPart: partTypeId,
      })
    );
  };

  const handleDeleteRFQPositions = (index) => {
    dispatch(
      partTypesActions.deleteSelectedPartTypes({
        index,
      })
    );
  };

  const renderRFQPositions = () => {
    return selectedPartTypesList.map((item, index) => {
      const isAddingCurrentPart = item.id === selectedNewPartId;

      return (
        <PartsCardConstructor
          key={uuidv4()}
          index={index}
          part={item}
          quantity={item.quantity}
          handleDeleteOrderPositions={handleDeleteRFQPositions}
          currentPage='RFQ'
          isShowDropdown={!isActiveStatus}
          isAddingCurrentPart={isAddingCurrentPart}
        />
      );
    });
  };

  const onUploadFile = (type) => (file, e) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    e.target.value = '';

    const fileTypeArray = FILE_ACCEPT_IMAGE_AND_PDF.split(',').map((item) =>
      item.trim()
    );

    if (!fileTypeArray.includes(file.type)) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_ERROR_TYPE_FILE_TITLE,
        variant: VARIANT_UI.danger,
      });

      return;
    }

    if (!file || file.size > MAX_FILE_SIZE) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_LARGE_SIZE_FILE_TEXT,
        variant: VARIANT_UI.danger,
      });
    } else {
      handleUploadFile(formData);
    }
  };

  const handleUploadFile = (file) => {
    const dataRFQ = {
      description: rfqInfo.description,
      part_type_ids: selectedPartTypesList.map((item) => item.id),
      files: currentRFQ.files ?? [],
    };
    dispatch(rfqsActions.addFile.start({ id: rfqId, file, dataRFQ }));
  };

  const handleRemoveFile = (path) => {
    const currentFiles = [...currentRFQ.files];
    const currentFilesIndex = currentFiles.indexOf(path);
    currentFiles.splice(currentFilesIndex, 1);

    const dataRFQ = {
      description: rfqInfo.description,
      part_type_ids: selectedPartTypesList.map((item) => item.id),
      files: currentFiles,
    };

    dispatch(rfqsActions.removeFiles.start({ id: rfqId, path, dataRFQ }));
  };

  useEffect(() => {
    if (descriptionId && textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, [descriptionId]);

  useEffect(() => {
    if (rfqId) {
      dispatch(rfqsActions.getFiles.start({ id: rfqId }));
    }
  }, [dispatch, rfqId]);

  useEffect(() => {
    let timerClearSelectedNewPartId = 0;

    if (selectedNewPartId && containerRef.current) {
      const arrayChildren = Array.from(containerRef.current?.children);

      const currentCard =
        arrayChildren?.find(
          ({ id }) => id.replace('part-', '') === selectedNewPartId.toString()
        ) || null;

      currentCard?.scrollIntoView({ behavior: 'smooth' });

      timerClearSelectedNewPartId = setTimeout(() => {
        dispatch(partTypesActions.clearSelectedNewPartId());
      }, 2500);
    }

    return () => {
      clearTimeout(timerClearSelectedNewPartId);
    };
  }, [selectedNewPartId, dispatch]);

  return (
    <div className={st.container}>
      {!isActiveStatus ? (
        <RFQConstructor onSubmit={addRFQPositions} />
      ) : (
        <Form.Label>
          <RequiredLabel />
          Part Number
        </Form.Label>
      )}
      <div
        className={cn(st.holderRFQPositionsList, {
          [st.holderRFQPositionsListActive]: isActiveStatus,
        })}
        ref={containerRef}>
        {selectedPartTypesList.length > 0 ? (
          renderRFQPositions()
        ) : (
          <div className={st.containerEmpty}>
            <Text variant='normal' className={st.emptyText}>
              {emptyText}
            </Text>
          </div>
        )}
      </div>
      <div className={st.holderRFQDescription}>
        <Form.Group className='mb-3'>
          <Form.Label>Description</Form.Label>
          <FormControl
            value={rfqInfo.description}
            onChange={handleChange}
            as='textarea'
            id={descriptionId}
            ref={textareaRef}
            placeholder='Text'
            maxLength={MAX_LENGTH_INPUT}
          />
        </Form.Group>
      </div>
      <RFQCommentsWrapper rfqId={rfqId} isNewRFQ={isNewRFQ} />
      <div className={st.containerFiles}>
        <div className={st.holderFiles}>
          <Text variant='normal' truncated className={st.filesLabel}>
            Attach File
          </Text>
          <FileList
            list={currentDataFiles?.[FILE_TYPES.attachment]}
            handleRemoveFile={handleRemoveFile}
            disableClearIcon={isLoadingFile}
            isShowPreview
          />
          <AttachFilesButton
            handleChange={onUploadFile(FILE_TYPES.attachment)}
            title='Add File'
            varinant={VARIANT_OUTLINE_UI.secondary}
            disabled={isLoadingFile || isNewRFQ}
            accept={FILE_ACCEPT_IMAGE_AND_PDF}
          />
        </div>
      </div>
    </div>
  );
}
export default RFQDetails;
