import React, { useEffect, useState, memo } from 'react';
import Text from 'componentsShared/Text/Text';
import cn from 'classnames';
import { string, bool, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { JOBS_TYPES_ALL } from 'constants/jobs';
import JobService from 'services/job/JobService';
import * as jobsActions from 'store/jobs/actions';

import st from './JobTabs.module.scss';

JobTabs.propTypes = {
  defaultActiveKey: string,
  onSelect: func,
  isShowBottomLine: bool,
  classNameContainerTabList: string,
  classNameWrapperTabList: string,
  classNameContainerContent: string,
  classNameContainer: string,
};

function JobTabs({
  children,
  defaultActiveKey = '',
  onSelect = () => {},
  isShowBottomLine = true,
  classNameContainerTabList,
  classNameWrapperTabList,
  classNameContainerContent,
  classNameContainer,
  changeSelectTab = null,
  setChangeSelectTab = () => {},
  jobData = [],
  onClick = () => {},
}) {
  const dispatch = useDispatch();

  const checkedJobId =
    useSelector((state) => state?.jobs?.checkJob?.jobId) ?? false;

  const isArrayChildren = children || Array.isArray(children);

  const [selectTab, setSelectTab] = useState(defaultActiveKey || '');

  const handleSelect = (eventKey) => {
    onSelect(eventKey);
    setSelectTab(eventKey);
  };

  const titleArray =
    (isArrayChildren &&
      children.map((item) => {
        const currentEventKey = item.props.eventKey;
        const key = `tab-${currentEventKey}`;
        const isActive = item.props.eventKey === selectTab;
        const title = item?.props?.title || '';

        return (
          <div
            className={st.holderTitleItem}
            onClick={() => {
              handleSelect(currentEventKey);
              onClick();
            }}
            key={key}>
            <Text variant='normal2' className={st.titleItem} bold>
              {title}
            </Text>
            {isActive && <div className={st.isActiveLine} />}
          </div>
        );
      })) ||
    [];

  const renderComponent = () => {
    if (!isArrayChildren) {
      return null;
    }

    const currentItem =
      children.find((item) => {
        return item.props.eventKey === selectTab;
      }) || null;

    const component = currentItem?.props?.children;
    return component;
  };

  useEffect(() => {
    if (isArrayChildren && !!children?.[0] && !defaultActiveKey) {
      const firstTabKey = children?.[0]?.props?.eventKey || '';
      const firstTabTitle = children?.[0]?.props?.title || '';

      setSelectTab(firstTabKey, firstTabTitle);
    }
  }, [children, isArrayChildren, defaultActiveKey, dispatch]);

  useEffect(() => {
    if (changeSelectTab && changeSelectTab !== selectTab) {
      handleSelect(changeSelectTab);
      setChangeSelectTab(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeSelectTab]);

  useEffect(() => {
    if (checkedJobId && jobData.length > 0 && selectTab !== JOBS_TYPES_ALL) {
      const typeTap = JobService.getCurrentTab(jobData, checkedJobId);
      handleSelect(typeTap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedJobId, jobData]);

  useEffect(() => {
    if (selectTab) {
      const currentData = children.find(
        (item) => item.props.eventKey === selectTab
      );

      const currentTitle = currentData?.props?.title;
      const validTitle = currentTitle && currentTitle.split('(')[0];
      dispatch(jobsActions.changeSelectJobTab(validTitle));
    }
  }, [selectTab, dispatch, children]);

  if (!isArrayChildren) {
    return null;
  }

  return (
    <div className={cn(classNameContainer)}>
      <div className={cn(st.containerTabList, classNameContainerTabList)}>
        <div className={cn(st.wrapperTabList, classNameWrapperTabList)}>
          <div className={st.holderTitleList}>
            {titleArray.map((item) => item)}
          </div>
          {isShowBottomLine && <div className={st.lineBottom} />}
        </div>
      </div>

      <div className={cn(st.containerContent, classNameContainerContent)}>
        {renderComponent()}
      </div>
    </div>
  );
}

export function JobTab({ children }) {
  return children;
}

export default memo(JobTabs);
