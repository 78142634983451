import {
  AccountStarOutlineIcon,
  CalendarTodayIcon,
  ClockOutlineIcon,
  DatabaseOutlineIcon,
  PaidIcon,
  TimelineIcon,
} from 'assets/icons';

export const ORDER_STATUS = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  NEW: 'NEW',
  DONE: 'DONE',
  CANCELED: 'CANCELED',
};

export const scoringMatrixData = {
  'Customer Relationship': {
    label: 'Customer Relationship',
    icon: <AccountStarOutlineIcon />,
  },
  Urgency: {
    label: 'Urgency',
    icon: <CalendarTodayIcon />,
  },
  'Value (Revenue)': {
    label: 'Value (Revenue)',
    icon: <ClockOutlineIcon />,
  },
  'Value (Margin)': {
    label: 'Value (Margin)',
    icon: <DatabaseOutlineIcon />,
  },
  'Part Complexity': {
    label: 'Part Complexity',
    icon: <PaidIcon />,
  },
  'Part Turnaround': {
    label: 'Part Turnaround',
    icon: <TimelineIcon />,
  },
};

export const READY_TO_SHIP_TEXT_STATUS = 'Ready to Ship';

export const READY_TO_SHIP_PHASE = 'outgoing';
