import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, Card, useAccordionButton, Button } from 'react-bootstrap';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import { ArrowDownIcon, EditIcon } from 'assets/icons';
import OrderService from 'services/order/OrderService';
import Status from 'componentsShared/Status/Status';
import {
  FORMAT_DATE_FULL_MONTH_KEBAB,
  VARIANT_OUTLINE_UI,
  VARIANT_UI,
} from 'constants/ui';
import Text from 'componentsShared/Text/Text';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import { object, bool, func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as ordersActions from 'store/orders/actions';
import Modal from 'componentsShared/Modal/Modal';
import {
  ORDER_STATUS,
  READY_TO_SHIP_PHASE,
  READY_TO_SHIP_TEXT_STATUS,
} from 'constants/order';
import { v4 as uuidv4 } from 'uuid';
import { isBeforeDate } from 'helpers/date';
import moment from 'moment';
import cn from 'classnames';
import ScorePOCard from 'components/ScorePOCard/ScorePOCard';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import AuthService from 'services/auth/AuthService';
import Highlighter from 'react-highlight-words';
import OrderItemPartCard from '../OrderItemPartCard/OrderItemPartCard';
import st from './OrderItem.module.scss';

OrderItem.propTypes = {
  order: object,
  isCustomer: bool,
  isProductionPOCard: bool,
  handleClickJobCard: func,
  searchValue: string,
};

const NUMBER_CARD_DISPLAYED = 10;

function OrderItem({
  order,
  isCustomer = false,
  isProductionPOCard = false,
  handleClickJobCard,
  findByJob = null,
  showDataOrderAndJob = '',
  page,
  searchValue = '',
}) {
  const dispatch = useDispatch();
  const isAdminRole = AuthService.isAdminRole();
  const isManagerRole = AuthService.isManagerRole();

  const arrowIcon = useRef(null);
  const isNewOrder =
    useSelector((state) => state?.orders?.isAddingOrder?.isNewOrder) ?? false;
  const addingOrderId =
    useSelector((state) => state?.orders?.isAddingOrder?.orderId) ?? null;
  const isOpenOrderItem =
    useSelector((state) => state?.orders?.orderCardList?.isOpenOrderList) ??
    false;
  const isChangeOrderItem =
    useSelector((state) => state?.orders?.orderCardList?.isChange) ?? false;

  const [isRemovingAnchor, setIsRemovingAnchor] = useState(false);
  const [isOpenAccordion, setIsOpenAccordion] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const isDraftPO = useMemo(() => {
    return order?.status === ORDER_STATUS.DRAFT;
  }, [order]);

  const countHiddenCard =
    order?.positions?.length - NUMBER_CARD_DISPLAYED > 0
      ? order.positions.length - NUMBER_CARD_DISPLAYED
      : 0;

  const isAddingCurrentOrder =
    isNewOrder && addingOrderId?.toString() === order.id?.toString();
  const endDate = order.end_date;
  const numberCrateData =
    moment(order.created).format(FORMAT_DATE_FULL_MONTH_KEBAB) ?? '';

  const endDateObject = useMemo(() => {
    let endDateValue = endDate
      ? moment(endDate).format(FORMAT_DATE_FULL_MONTH_KEBAB)
      : '';
    const isErrorDateEnd = endDateValue
      ? isBeforeDate(new Date(), endDate)
      : true;

    if (!endDateValue) {
      endDateValue = isCustomer ? 'Estimating...' : 'Not specified';
    }

    return {
      endDateValue,
      isErrorDateEnd,
    };
  }, [endDate, isCustomer]);

  const customerName = useMemo(
    () => (order?.customer_name ? `${order.customer_name}: ` : ''),
    [order?.customer_name]
  );

  const numberPO = useMemo(
    () => (order?.purchase_number ? `${order.purchase_number}` : ''),
    [order?.purchase_number]
  );

  const isShowCancelButton = useMemo(
    () =>
      (isAdminRole || isManagerRole) &&
      (order?.status === ORDER_STATUS.DRAFT ||
        (order?.status === ORDER_STATUS.ACTIVE &&
          order.job_details &&
          order.job_details.every((item) => item.progress === 0))),
    [order, isAdminRole, isManagerRole]
  );

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setIsOpenAccordion((prev) => !prev);
    });

    return (
      <div className={st.orderItemHeader} onClick={decoratedOnClick}>
        {children}
      </div>
    );
  }

  const handleRemovePO = () => {
    dispatch(ordersActions.removeOrder.start(order.id));
    setIsRemovingAnchor(true);
  };

  const handleOpenOrderItem = () => {
    dispatch(ordersActions.openOrderItem(order.id));
  };

  const statusOrder = useMemo(() => {
    if (isCustomer && order.status === ORDER_STATUS.DRAFT) {
      return ORDER_STATUS.NEW;
    }
    return order.status;
  }, [isCustomer, order.status]);

  const handleEditPO = (e) => {
    e.stopPropagation();

    dispatch(
      ordersActions.changeDataOrderAndJob(
        `${ORDER_STATUS.DRAFT}-${order.id}-${page}`
      )
    );

    if (OrderService.isStatusDraft(order.id)) {
      NavigationService.navigateToPath(
        getRoutePath(ROUTES.ordersDetails.path, {
          id: order.id,
        })
      );
    }
  };

  const handleCancelOrder = () => {
    dispatch(
      ordersActions.cancelOrder.start({
        id: order.id,
        isProductionPage: isProductionPOCard,
      })
    );
  };

  const dropdownList = [
    {
      content: () =>
        order?.status === ORDER_STATUS.DRAFT ? 'Remove PO' : 'Cancel PO',
      onClick: () => setShowModalRemove(true),
    },
  ];

  const renderPositionList = () => {
    return (
      <ul className={st.orderPositionList}>
        {order?.positions?.map((position, index) => {
          const isHiddenCard = index + 1 > NUMBER_CARD_DISPLAYED;
          const progress = position?.progress || 0;
          const jobDetails = order.job_details;
          const partTypeId = position?.part_type?.id;
          const currentJobDetails =
            jobDetails?.filter((item) => partTypeId === item.part_type_id) ||
            [];

          const isFunction = jobDetails?.length > 0 || false;

          if (isHiddenCard && !order.show) {
            return;
          }
          return (
            <OrderItemPartCard
              key={uuidv4()}
              position={position}
              currentJobDetails={currentJobDetails}
              progress={progress}
              order={order}
              handleClickJobCard={isFunction && handleClickJobCard}
              isCustomer={isCustomer}
              findByJob={findByJob}
              showDataOrderAndJob={showDataOrderAndJob}
              searchValue={searchValue}
            />
          );
        })}
      </ul>
    );
  };

  const readyForShipStatus = useMemo(() => {
    return order?.positions?.every((item) => {
      const currentJobDetails = order.job_details?.filter((details) => {
        return item.part_type.id === details.part_type_id;
      });

      return (
        currentJobDetails?.some((value) => {
          return value.phase_name === READY_TO_SHIP_PHASE;
        }) &&
        currentJobDetails?.every?.(
          (value) =>
            value.phase_name === READY_TO_SHIP_PHASE || ORDER_STATUS.DONE
        )
      );
    });
  }, [order]);

  const renderOrderItemAdditionalInfo = () => {
    if (isProductionPOCard) {
      return <ScorePOCard order={order} />;
    }

    return (
      <div className={st.orderItemAdditionalInfo}>
        {OrderService.isStatusDraft(order.id) && (
          <Button
            onClick={handleEditPO}
            variant={VARIANT_OUTLINE_UI.secondary}
            size='sm'
            className={st.buttonEdit}>
            <EditIcon className={st.editIcon} />
            Edit
          </Button>
        )}
        <Status
          title={statusOrder}
          variant={VARIANT_UI.info}
          className={st.orderItemStatus}
          size='small'
        />
      </div>
    );
  };

  useEffect(() => {
    if (isChangeOrderItem) {
      setIsOpenAccordion(isOpenOrderItem);
      dispatch(ordersActions.deactivateOrderCardListChange());
    }
  }, [dispatch, isOpenOrderItem, isChangeOrderItem]);

  useEffect(() => {
    const clearIsAddingOrderTimer = setTimeout(() => {
      dispatch(ordersActions.clearIsAddingOrder());
    }, 2000);

    return () => clearTimeout(clearIsAddingOrderTimer);
  }, [dispatch]);

  useEffect(() => {
    if (findByJob) {
      setIsOpenAccordion(findByJob);
    }
  }, [findByJob]);

  useEffect(() => {
    if (showDataOrderAndJob) {
      const [, orderScroll] = showDataOrderAndJob.split('-');

      if (orderScroll.toString() === order.id.toString()) {
        setIsOpenAccordion(true);
      }
    }
  }, [showDataOrderAndJob, order]);

  return (
    <>
      <div className={st.rootWrapper} id={`order-card-${order.id}`}>
        <Accordion
          activeKey={isOpenAccordion && order.id}
          key={order.id}
          className={st.orderAccordion}>
          <Card>
            <Card.Header className={st.orderAccordionCardHeader}>
              <CustomToggle eventKey={order.id}>
                <div className={st.orderAccordionToggle}>
                  <div className={st.holderArrow}>
                    <ArrowDownIcon
                      ref={arrowIcon}
                      className={cn(st.orderAccordionArrow, {
                        [st.orderAccordionArrowClose]: !isOpenAccordion,
                      })}
                    />
                  </div>
                  <div className={st.orderAccordionInfo}>
                    <div className={st.holderHeader}>
                      <div
                        className={cn(st.holderHeaderTitle, {
                          [st.holderHeaderTitleCustomer]: isCustomer,
                        })}>
                        <Text variant='normal' bold className={st.headerTitle}>
                          {isProductionPOCard && customerName}
                          <Highlighter
                            searchWords={[searchValue]}
                            autoEscape
                            textToHighlight={numberPO}
                            className={st.highlighter}
                          />
                        </Text>
                      </div>

                      {isShowCancelButton && (
                        <>
                          <DropdownButton
                            id='dropdown-burger-cancel-button'
                            title={<ThreeDotsVertical />}
                            className={st.burgerBtn}
                            list={dropdownList}
                            btnVariant={VARIANT_OUTLINE_UI.light}
                            onClick={(e) => e.stopPropagation()}
                            direction='down'
                          />
                        </>
                      )}
                      {isCustomer && readyForShipStatus && (
                        <Status
                          title={READY_TO_SHIP_TEXT_STATUS}
                          variant={VARIANT_UI.primary}
                          size='small'
                        />
                      )}
                    </div>

                    <div className={st.wrapperAdditionalInfoCardPO}>
                      <div className={st.wrapperDate}>
                        <div className={st.holderDate}>
                          <Text
                            variant='normal2'
                            className={st.previewTextDate}>
                            Start:{' '}
                          </Text>
                          <Text variant='normal2' className={st.textDate}>
                            {numberCrateData}
                          </Text>
                        </div>
                        <div className={st.holderDate}>
                          <Text
                            variant='normal2'
                            className={st.previewTextDate}>
                            Due:{' '}
                          </Text>
                          <Text
                            variant='normal2'
                            className={cn(st.textDate, {
                              [st.expiredDate]:
                                !isCustomer && !endDateObject.isErrorDateEnd,
                            })}>
                            {endDateObject.endDateValue}
                          </Text>
                        </div>
                      </div>
                      {renderOrderItemAdditionalInfo()}
                    </div>
                  </div>
                </div>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse
              eventKey={order.id}
              className={st.accordionCollapse}>
              <Card.Body>
                <div>{renderPositionList()}</div>
                {!!countHiddenCard && !order.show && (
                  <div className={st.holderCountHiddenCard}>
                    <div
                      className={st.btnCountHiddenCard}
                      onClick={handleOpenOrderItem}>
                      <Text
                        variant='normal2'
                        className={st.btnCountHiddenCardText}>
                        + View All ({countHiddenCard} more)
                      </Text>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <div
          className={cn(st.removingOverlay, {
            [st.removingOverlayVisible]: isRemovingAnchor,
          })}
        />
        {isAddingCurrentOrder && <div className={st.addingAnimation} />}
      </div>
      <Modal
        title={isDraftPO ? 'Removing Product Order' : 'Canceling Product Order'}
        show={showModalRemove}
        setShow={setShowModalRemove}
        titleAcceptBtn={isDraftPO ? 'Remove PO' : 'Cancel PO with jobs'}
        onAcceptBtn={isDraftPO ? handleRemovePO : handleCancelOrder}
        variantAcceptBtn={VARIANT_UI.primary}>
        <div className={st.wrapperModalContent}>
          <Text variant='normal'>
            Order:{' '}
            <Text variant='normal' bold>
              {order.purchase_number}
            </Text>
          </Text>
          {!isDraftPO && (
            <Text variant='normal'>
              Jobs:{' '}
              <Text variant='normal' bold>
                {order?.job_details?.length}
              </Text>
            </Text>
          )}
          <br />
          <Text variant='normal'>
            {!isDraftPO && (
              <>
                By canceling PO you will delete it’s jobs as well.
                <br />
              </>
            )}
            This action can’t bereverted.
          </Text>
        </div>
      </Modal>
    </>
  );
}

export default memo(OrderItem);
