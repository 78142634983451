import React, { useEffect } from 'react';
import Text from 'componentsShared/Text/Text';
import {
  MAX_FILE_SIZE,
  NOTIFICATION_ERROR_TYPE_FILE_TITLE,
  NOTIFICATION_LARGE_SIZE_FILE_TEXT,
  NOTIFICATION_LARGE_SIZE_FILE_TITLE,
  VARIANT_OUTLINE_UI,
  VARIANT_UI,
} from 'constants/ui';
import { useDispatch, useSelector } from 'react-redux';
import * as controlValuesActions from 'store/controlValues/actions';
import * as jobsActions from 'store/jobs/actions';
import JobsQAListOperation from 'components/Jobs/JobsQAListOperation/JobsQAListOperation';
import FileList from 'componentsShared/FileList/FileList';
import { FILE_ACCEPT_IMAGE_AND_PDF, FILE_TYPES } from 'constants/files';
import AttachFilesButton from 'components/AttachFilesButton/AttachFilesButton';
import NotificationsService from 'services/notifications/NotificationsService';
import st from './JobsDetailsQA.module.scss';

function JobsDetailsQA({ job }) {
  const dispatch = useDispatch();

  const currentDataFiles =
    useSelector((state) => state?.jobs?.files?.data?.[job?.id]) ?? {};
  const isLoadingFiles =
    useSelector((state) => state?.jobs?.files?.isLoading) ?? false;

  const bundleId = job?.current_step?.bundle_id;

  const handleUploadFile = (file) => {
    dispatch(
      jobsActions.addFileInterprocessQA.start({ idList: [job.id], file })
    );
  };

  const handleRemoveFile = (path) => {
    dispatch(jobsActions.removeFileInterprocessQA.start({ id: job.id, path }));
  };

  const onUploadFile = (type) => (file, e) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    e.target.value = '';

    const fileTypeArray = FILE_ACCEPT_IMAGE_AND_PDF.split(',').map((item) =>
      item.trim()
    );

    if (!fileTypeArray.includes(file.type)) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_ERROR_TYPE_FILE_TITLE,
        variant: VARIANT_UI.danger,
      });

      return;
    }

    if (!file || file.size > MAX_FILE_SIZE) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_LARGE_SIZE_FILE_TEXT,
        variant: VARIANT_UI.danger,
      });
    } else {
      handleUploadFile(formData);
    }
  };

  useEffect(() => {
    if (bundleId) {
      dispatch(controlValuesActions.getControlValues.start(bundleId));
    }
  }, [dispatch, bundleId]);

  useEffect(() => {
    if (job.id) {
      dispatch(jobsActions.getFileInterprocessQA.start({ id: job.id }));
    }
  }, [dispatch, job.id]);

  return (
    <div className={st.container}>
      <div>
        <JobsQAListOperation step={job?.current_step} />
      </div>
      <div className={st.containerFiles}>
        <Text variant='normal' bold>
          Attached Files
        </Text>
        <FileList
          list={currentDataFiles?.[FILE_TYPES.qaAttachment]}
          handleRemoveFile={handleRemoveFile}
          disableClearIcon={isLoadingFiles}
          isShowPreview
        />
        <AttachFilesButton
          handleChange={onUploadFile(FILE_TYPES.qaAttachment)}
          title='Add File'
          varinant={VARIANT_OUTLINE_UI.secondary}
          disabled={isLoadingFiles}
          accept={FILE_ACCEPT_IMAGE_AND_PDF}
          size='sm'
        />
      </div>
    </div>
  );
}

export default JobsDetailsQA;
